import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogGlossaryComponent } from 'app/dialog/dialog-glossary/dialog-glossary.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { CommonTable } from 'app/model/entity/commonTable';
import { Notification } from 'app/model/entity/notification';
import { SpeechSynthesis } from 'app/model/entity/speech-synthesis';
import { Translation } from 'app/model/entity/translation';
import { AnnouncementManagerService } from 'app/service/announcement/announcement-manager.service';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { FujiClimberManagementService } from 'app/service/fuji-climber-management.service';
import _ from 'lodash';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'tab-push-notification',
  templateUrl: './tab-push-notification.component.html',
  styleUrls: ['./tab-push-notification.component.scss']
})
export class TabPushNotificationComponent implements OnInit, OnDestroy {
  translation: Translation = new Translation();
  languagesTranslatioMandatory = []; // List language for translation (alway have "jp" & "en")
  languagesTranslation = []; // List language for translation item
  public languageKey: string;
  public languageCode: string;
  listSpeechSynthesis: Array<SpeechSynthesis> = new Array<SpeechSynthesis>(); // List Speech Synthesis
  listSpeechSynthesisCloneDeep: Array<SpeechSynthesis> = new Array<SpeechSynthesis>(); // List Speech Synthesis clone Deep

  isDisableTranslate: boolean;
  listActive: Set<string> = new Set();
  fileGlossary: string;
  bucketSaveAudio: string;
  private commonObject: Common;
  Helper = Helper;
  public isCheckedAllLanguage: boolean;
  notificationHistories: Notification[];
  notificationSelected: Notification;

  hasScroll: boolean;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription

  /**
   * Tile header for table Speech Synthesis
   */
  titleHeaderSpeechSynthesis = [
    { title: 'checkAll', value: '' },
    { title: 'language', value: 'fuji-climber-management.tab-push-notification.language' },
    { title: 'title', value: 'fuji-climber-management.tab-push-notification.title-header' },
    { title: 'text', value: 'fuji-climber-management.tab-push-notification.text-header' }
  ];

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private announcementManagerService: AnnouncementManagerService,
    private commonService: CommonService,
    private dataService: DataService,
    private fujiClimberManagementService: FujiClimberManagementService,
    private cdr: ChangeDetectorRef,
    private commonTableService: CommonTableService
  ) {
    this.commonObject = this.commonService.getCommonObject();
  }

  async ngOnInit(): Promise<void> {
    await this.getEndPoint();
    this.languageKey = this.commonObject?.setting?.language;
    this.languageCode = this.languageKey == 'en' ? this.languageKey : 'ja';
    this.notificationHistories = [];
    this.getLanguagesTranslation();
    this.getNotificationHistory();
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_LANGUAGE_PUSH_NOTIFICATION).subscribe(data => {
      if (data) {
        const language = JSON.parse(data.value);
        if (language.length) {
          const indexLanguageCode = language.findIndex(e => e == this.listSpeechSynthesis[0].translationLanguageCode);
          if (indexLanguageCode != -1) {
            language.splice(indexLanguageCode, 1);
          }
          language.forEach((e, index) => {
            this.listSpeechSynthesis[index + 1].translationLanguageCode = e;
            this.changeLanguage(this.listSpeechSynthesis[index + 1], false);
          });
        }
      }
    });
    this.subscriptions.push(
      this.dataService.currentData.subscribe(data => {
        if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
          this.languageKey = this.commonObject?.setting?.language;
          this.languageCode = this.languageKey == 'en' ? this.languageKey : 'ja';
          this.getLanguagesTranslation();
          this.clearText();
          this.saveLanguageToCommonTable();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.saveLanguageToCommonTable();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit() {
    const element = document.getElementById('table-data');

    const observer = new ResizeObserver(() => {
      if (element) {
        const hasScroll = element.scrollHeight > element.clientHeight;
        this.hasScroll = hasScroll;
        this.cdr.detectChanges();
      }
    });

    if (element) {
      observer.observe(element);
    }

    // Clean up khi component bị hủy
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }

  /**
   * get getEndPoint
   * @returns
   */
  private async getEndPoint(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.announcementManagerService.getEndPoint().subscribe(
        data => {
          this.announcementManagerService.endPoint = data;
          resolve();
        },
        error => {
          reject();
          Helper.handleError(error, this.translateService, this.dialogService);
        }
      );
    });
  }

  /**
   * Get languages translation
   */
  private getLanguagesTranslation(): void {
    let languagesSetting = [];
    if (this.commonObject.setting?.languagesSetting) {
      languagesSetting = this.commonObject.setting?.languagesSetting.includes('"')
        ? this.commonObject.setting?.languagesSetting.slice(1, -1).split(',')
        : this.commonObject.setting?.languagesSetting.split(',');
    }
    this.languagesTranslation = Constant.LANGUAGES_SETTING.filter(e => {
      return languagesSetting.includes(e.translation_language_code);
    });
    let languagesSettingMandatory = languagesSetting.slice();
    if (!languagesSettingMandatory.includes('en')) {
      languagesSettingMandatory.unshift('en');
      this.languagesTranslation.unshift(Constant.LANGUAGES_SETTING.find(e => e.translation_language_code == 'en'));
    }
    if (!languagesSettingMandatory.includes('ja')) {
      languagesSettingMandatory.unshift('ja');
      this.languagesTranslation.unshift(Constant.LANGUAGES_SETTING.find(e => e.translation_language_code == 'ja'));
    }
    this.languagesTranslatioMandatory = Constant.LANGUAGES_SETTING.filter(e => {
      return languagesSettingMandatory.includes(e.translation_language_code);
    });
    this.setDefaultTranslation();
    this.setDefaultSpeech();
  }

  /**
   * set Default Translation
   */
  private setDefaultTranslation(): void {
    this.translation = new Translation();
    this.translation.baseLanguageCode = this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey;
    this.translation.sourceLanguageCode = this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey;
  }

  /**
   * set Default Speech
   */
  private setDefaultSpeech(): void {
    this.listActive = new Set<string>();
    const LENGTH_ARRAY = 10;
    this.listSpeechSynthesis = [];
    for (let i = 0; i < LENGTH_ARRAY; i++) {
      let speechSynthesis = new SpeechSynthesis();
      this.listSpeechSynthesis.push(speechSynthesis);
    }
    if (this.listSpeechSynthesis.length) {
      const speechSynthesis = this.listSpeechSynthesis[0];
      speechSynthesis.translationLanguageCode = this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey;
      this.listActive.add(speechSynthesis.translationLanguageCode);
      if (this.languagesTranslation.length) {
        const languageCode = this.languagesTranslation.find(e => e['translation_language_code'] == speechSynthesis.translationLanguageCode);
        if (languageCode) {
          speechSynthesis.pollyLanguageCode = languageCode['polly_language_code']
            ? languageCode['polly_language_code']
            : speechSynthesis.translationLanguageCode;
        }
        let index = this.languagesTranslation.findIndex(e => e['translation_language_code'] == speechSynthesis.translationLanguageCode);
        if (index != -1) {
          speechSynthesis.languageNameEn = this.languagesTranslation[index]['language_name_en'];
          speechSynthesis.languageNameJa = this.languagesTranslation[index]['language_name_ja'];
          speechSynthesis.isAvailable = this.languagesTranslation[index]['polly_available'];
        }
      }
      speechSynthesis.translatedText = '';
      speechSynthesis.retranslatedText = '';
      speechSynthesis.isChangeReText = false;
      speechSynthesis.isChangeText = false;
      speechSynthesis.isAllowsTextInput = true;
      speechSynthesis.isEditText = false;
      this.isCheckedAllLanguage = false;
      this.listSpeechSynthesisCloneDeep = _.cloneDeep(this.listSpeechSynthesis);
    }
  }

  /**
   * getNotificationHistory
   */
  getNotificationHistory() {
    this.fujiClimberManagementService.getNotificationHistory().subscribe(
      data => {
        if (data && data['notificationList']) {
          this.notificationHistories = data['notificationList'];
        } else {
          this.notificationHistories = [];
        }
      },
      error => {
        this.notificationHistories = [];
      }
    );
  }

  /**
   * check All Language
   */
  public checkAllLanguage(): void {
    this.isCheckedAllLanguage = !this.isCheckedAllLanguage;
    this.listSpeechSynthesis.forEach(e => {
      if (e.translationLanguageCode) {
        e.isChecked = this.isCheckedAllLanguage;
      }
    });
  }

  /**
   * clearText
   */
  public clearText(): void {
    this.translation.title = '';
    this.translation.text = '';
    this.listSpeechSynthesis.forEach(e => {
      if (e.translationLanguageCode) {
        e.translatedTitle = '';
        e.translatedText = '';
        e.retranslatedTitle = '';
        e.retranslatedText = '';
      }
    });
  }

  /**
   * glossary
   */
  public glossary(): void {
    this.dialogService.showDialog(
      DialogGlossaryComponent,
      {
        data: {
          screen: Constant.PUSH_NOTIFICATION_COMPONENT_URL
        }
      },
      result => {
        this.fileGlossary = result;
      }
    );
  }

  /**
   * checkDisableGlossary
   * @returns
   */
  checkDisableGlossary(): boolean {
    return this.listSpeechSynthesis.some(e => e.isEditText);
  }

  /**
   * disableTranslate
   * @param status
   */
  disableTranslate(status: boolean) {
    this.isDisableTranslate = status;
  }

  /**
   * check Disable button translate
   * @returns
   */
  public checkDisableTranslate(): boolean {
    return this.listSpeechSynthesis.some(e => e.isEditText);
  }

  /**
   * checkValidLanguageTranslate
   * @returns
   */
  public checkValidLanguageTranslate(): boolean {
    return this.listActive.size ? true : false;
  }

  /**
   * translate
   */
  public translate(): void {
    this.listSpeechSynthesis
      .filter(e => e.translationLanguageCode)
      .forEach(e => {
        e.isChangeReText = false;
        e.isChangeText = false;
      });
    let speechChecked = this.listSpeechSynthesis.filter(e => e.isChecked);
    if (speechChecked.length) {
      this.handleConfirmDeleteSpeech(speechChecked);
    } else {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.translate-all-language'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          this.handleConfirmDeleteSpeech(this.listSpeechSynthesis);
        }
      );
    }
  }

  /**
   * handleConfirmDeleteSpeech
   *
   * @param listSpeechSynthesis
   */
  handleConfirmDeleteSpeech(listSpeechSynthesis: Array<SpeechSynthesis>): void {
    let targetLanguageCode = [];
    targetLanguageCode = listSpeechSynthesis.filter(item => item.translationLanguageCode).map(e => e.translationLanguageCode);
    this.handleCallAPITranslate(targetLanguageCode);
  }

  /**
   * handle Call API Translate
   * @param targetLanguageCode
   */
  private handleCallAPITranslate(targetLanguageCode: any[]): void {
    const MAX_LENGTH_INPUT_TITLE = 100;
    const MAX_LENGTH_INPUT_TEXT = 600;
    let payloadTitle = {
      base_language_code: this.translation.baseLanguageCode,
      text: Helper.encodeHTMLAnnouncementManager(this.translation.title),
      source_language_code: this.translation.sourceLanguageCode,
      target_language_code: targetLanguageCode,
      glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_push_nortification` : ''
    };
    let payloadText = {
      base_language_code: this.translation.baseLanguageCode,
      text: Helper.encodeHTMLAnnouncementManager(this.translation.text),
      source_language_code: this.translation.sourceLanguageCode,
      target_language_code: targetLanguageCode,
      glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_push_nortification` : ''
    };

    forkJoin({
      responseTitle: this.announcementManagerService.translate(payloadTitle),
      responseText: this.announcementManagerService.translate(payloadText)
    }).subscribe(data => {
      const responseTitle = data.responseTitle['message'];
      const responseText = data.responseText['message'];
      let languageInValid = new Array<String>();
      //Title
      if (responseTitle.length) {
        for (let e of responseTitle) {
          if (e.translated_text.length > MAX_LENGTH_INPUT_TITLE) {
            const languageKey =
              this.languageKey == 'en'
                ? Constant.LANGUAGES_SETTING.find(item => item.translation_language_code == e.target_language_code).language_name_en
                : Constant.LANGUAGES_SETTING.find(item => item.translation_language_code == e.target_language_code).language_name_ja;
            languageInValid.push(`・${languageKey}`);
          }
        }
        if (languageInValid.length && !responseText.length) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('announcement-manager.error'),
              text: this.translateService.instant('announcement-manager.error-max-length'),
              texts: languageInValid
            }
          });
        }
        for (let message of responseTitle) {
          this.listSpeechSynthesis.forEach(speechSynthesis => {
            if (speechSynthesis.translationLanguageCode == message['target_language_code']) {
              speechSynthesis.translatedTitle =
                message['translated_text'].length > MAX_LENGTH_INPUT_TITLE
                  ? Helper.reEncodeHTMLAnnouncementManager(message['translated_text'].substring(0, MAX_LENGTH_INPUT_TITLE))
                  : Helper.reEncodeHTMLAnnouncementManager(message['translated_text']);
              speechSynthesis.retranslatedTitle = Helper.reEncodeHTMLAnnouncementManager(message['retranslated_text']);
            }
          });
        }
      }

      //Text
      if (responseText.length) {
        for (let e of responseText) {
          if (e.translated_text.length > MAX_LENGTH_INPUT_TEXT) {
            const languageKey =
              this.languageKey == 'en'
                ? Constant.LANGUAGES_SETTING.find(item => item.translation_language_code == e.target_language_code).language_name_en
                : Constant.LANGUAGES_SETTING.find(item => item.translation_language_code == e.target_language_code).language_name_ja;
            languageInValid.push(`・${languageKey}`);
          }
        }
        languageInValid = [...new Set(languageInValid)];
        if (languageInValid.length) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('announcement-manager.error'),
              text: this.translateService.instant('announcement-manager.error-max-length'),
              texts: languageInValid
            }
          });
        }
        for (let message of responseText) {
          this.listSpeechSynthesis.forEach(speechSynthesis => {
            speechSynthesis.isChecked = false;
            if (speechSynthesis.translationLanguageCode == message['target_language_code']) {
              speechSynthesis.translatedText =
                message['translated_text'].length > MAX_LENGTH_INPUT_TEXT
                  ? Helper.reEncodeHTMLAnnouncementManager(message['translated_text'].substring(0, MAX_LENGTH_INPUT_TEXT))
                  : Helper.reEncodeHTMLAnnouncementManager(message['translated_text']);
              speechSynthesis.retranslatedText = Helper.reEncodeHTMLAnnouncementManager(message['retranslated_text']);
            }
          });
        }
      }

      this.isCheckedAllLanguage = false;
      this.listSpeechSynthesisCloneDeep = _.cloneDeep(this.listSpeechSynthesis);
    });
  }

  /**
   * check Disable button Speech synthesis
   * @returns
   */
  public checkDisableSend(): boolean {
    return this.listSpeechSynthesis.every(e => !e.translatedTitle && !e.translatedText);
  }

  /**
   * handle click button speech Synthesis
   */
  public send(): void {
    let speechChecked = this.listSpeechSynthesis.filter(e => e.isChecked);
    if (speechChecked.length) {
      this.handleCallAPISendNotification(speechChecked);
    } else {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('fuji-climber-management.tab-push-notification.send-all'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          let listSpeechSynthesis = this.listSpeechSynthesis.filter(e => e.translationLanguageCode);
          this.handleCallAPISendNotification(listSpeechSynthesis);
        }
      );
    }
  }

  /**
   * validateBeforeSend
   */
  validateBeforeSend(targetLanguageCode: Array<SpeechSynthesis>): boolean {
    if (targetLanguageCode.some(e => !e.translatedTitle || !e.translatedText)) {
      return true;
    }
    return false;
  }

  /**
   * handleCallAPISendNotification
   * @param targetLanguageCode
   */
  handleCallAPISendNotification(targetLanguageCode: Array<SpeechSynthesis>) {
    if (this.validateBeforeSend(targetLanguageCode)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('fuji-climber-management.tab-push-notification.error'),
          text: this.translateService.instant('fuji-climber-management.tab-push-notification.error-send')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('fuji-climber-management.tab-push-notification.confirm-send'),
          button1: this.translateService.instant('fuji-climber-management.tab-push-notification.send'),
          button2: this.translateService.instant('fuji-climber-management.tab-push-notification.cancel')
        }
      },
      result => {
        if (!result) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('fuji-climber-management.tab-push-notification.cancel'),
              text: this.translateService.instant('fuji-climber-management.tab-push-notification.push-notification-cancel')
            }
          });
          return;
        }
        const notificationTitle = {};
        const notificationBody = {};
        targetLanguageCode.forEach(item => {
          notificationTitle[item.translationLanguageCode] = item.translatedTitle;
          notificationBody[item.translationLanguageCode] = item.translatedText;
        });
        const payload = {
          notificationTitle: notificationTitle,
          notificationBody: notificationBody,
          originalLanguage: this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey
        };
        this.fujiClimberManagementService.sendNotification(payload).subscribe(
          data => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('fuji-climber-management.tab-push-notification.success'),
                text: this.translateService.instant('fuji-climber-management.tab-push-notification.push-notification-success')
              }
            });
            this.getNotificationHistory();
            this.listSpeechSynthesis.forEach(e => (e.isChecked = false));
            this.isCheckedAllLanguage = false;
          },
          error => {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('fuji-climber-management.tab-push-notification.error'),
                text: this.translateService.instant('fuji-climber-management.tab-push-notification.push-notification-fail')
              }
            });
          }
        );
      }
    );
  }

  /**
   * handle Change Language Code
   * @param speechSynthesis
   */
  public handleChangeLanguageCode(speechSynthesis: SpeechSynthesis): void {
    if (speechSynthesis.translatedText) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.confirm-change-language-hasnt-speech'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        data => {
          if (!data) {
            const index = this.listSpeechSynthesis.findIndex(e => e.translatedText == speechSynthesis.translatedText);
            speechSynthesis.translationLanguageCode = _.cloneDeep(this.listSpeechSynthesisCloneDeep[index].translationLanguageCode);
            return;
          }
          this.changeLanguage(speechSynthesis, true);
        }
      );
    } else {
      this.changeLanguage(speechSynthesis, true);
    }
    const listSpeechSynthesis = this.listSpeechSynthesis.filter(e => e.translationLanguageCode);
    this.isCheckedAllLanguage = listSpeechSynthesis.length ? listSpeechSynthesis.every(e => e.isChecked) : false;
  }

  /**
   * changeLanguge
   * @param speechSynthesis
   */
  changeLanguage(speechSynthesis: SpeechSynthesis, saveLanguage?: boolean): void {
    if (speechSynthesis.languageNameEn) {
      const languageNameEnOld = _.cloneDeep(speechSynthesis.languageNameEn);
      const indexToRemove = this.languagesTranslation.findIndex(item => item['language_name_en'] == languageNameEnOld);
      // Xoa language da duoc chon
      if (indexToRemove != -1) {
        this.listActive.forEach(e => {
          if (e == this.languagesTranslation[indexToRemove]['translation_language_code']) {
            this.listActive.delete(e);
          }
        });
      }
    }
    if (speechSynthesis.translationLanguageCode) {
      const languageCode = this.languagesTranslation.find(e => e['translation_language_code'] == speechSynthesis.translationLanguageCode);
      this.listActive.add(speechSynthesis.translationLanguageCode);
      speechSynthesis.pollyLanguageCode = languageCode['polly_language_code']
        ? languageCode['polly_language_code']
        : speechSynthesis.translationLanguageCode;
      let index = this.languagesTranslation.findIndex(e => e['translation_language_code'] == speechSynthesis.translationLanguageCode);
      speechSynthesis.languageNameEn = this.languagesTranslation[index]['language_name_en'];
      speechSynthesis.languageNameJa = this.languagesTranslation[index]['language_name_ja'];
      speechSynthesis.isAvailable = this.languagesTranslation[index]['polly_available'];
      speechSynthesis.translatedText = '';
      speechSynthesis.retranslatedText = '';
      speechSynthesis.translatedTitle = '';
      speechSynthesis.retranslatedTitle = '';
      speechSynthesis.isChangeReText = false;
      speechSynthesis.isChangeText = false;
      speechSynthesis.isAllowsTextInput = true;
      speechSynthesis.isEditText = false;
    } else {
      speechSynthesis.pollyLanguageCode = '';
      speechSynthesis.languageNameEn = '';
      speechSynthesis.languageNameJa = '';
      speechSynthesis.isAvailable = false;
      speechSynthesis.translatedText = '';
      speechSynthesis.retranslatedText = '';
      speechSynthesis.translatedTitle = '';
      speechSynthesis.retranslatedTitle = '';
      speechSynthesis.isChangeReText = false;
      speechSynthesis.isChangeText = false;
      speechSynthesis.isAllowsTextInput = false;
      speechSynthesis.isEditText = false;
      speechSynthesis.isChecked = false;
    }
    this.listSpeechSynthesisCloneDeep = _.cloneDeep(this.listSpeechSynthesis);
    const language = [];
    this.listSpeechSynthesis.forEach((e, index) => {
      if (index != 0 && e.translationLanguageCode) {
        language.push(e.translationLanguageCode);
      }
    });
    if (saveLanguage) {
      let commonTableSave = new CommonTable();
      commonTableSave.key = Constant.KEY_LANGUAGE_PUSH_NOTIFICATION;
      commonTableSave.value = JSON.stringify(language);
      this.dataService.sendData([Constant.IS_SAVING_LANGUAGE_PUSH_NOTIFICATION, true]);
      this.commonTableService.save(commonTableSave).subscribe(
        data => {
          this.dataService.sendData([Constant.IS_SAVING_LANGUAGE_PUSH_NOTIFICATION, false]);
        },
        error => {
          this.dataService.sendData([Constant.IS_SAVING_LANGUAGE_PUSH_NOTIFICATION, false]);
        }
      );
    }
  }

  /**
   * edit Speech Synthesis
   * @param speechSynthesis
   * @param event
   * @returns
   */
  public editTranslatedTitle(speechSynthesis: SpeechSynthesis, event: any): void {
    if (!event) {
      event.preventDefault();
      return;
    }
    if (!speechSynthesis.isEditTitle) {
      speechSynthesis.isEditTitle = !speechSynthesis.isEditTitle;
    }
    if (event.keyCode == Constant.ENTER_KEY_CODE && speechSynthesis.isEditTitle) {
      let payload = {
        source_language_code: speechSynthesis.translationLanguageCode,
        text: Helper.encodeHTMLAnnouncementManager(speechSynthesis.translatedTitle),
        target_language_code: this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey,
        glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_push_nortification` : ''
      };

      if (!speechSynthesis.retranslatedTitle && speechSynthesis.translatedTitle.trim().length) {
        this.announcementManagerService.singleRetranslation(payload).subscribe(res => {
          const data = res['message'];
          speechSynthesis.retranslatedTitle = Helper.reEncodeHTMLAnnouncementManager(data['retranslated_text']);
        });
      }
      speechSynthesis.isEditTitle = !speechSynthesis.isEditTitle;
    }
  }

  /**
   * change Speech Text
   * @param speechSynthesis
   */
  public changeTranslatedTitle(speechSynthesis: SpeechSynthesis): void {
    if (speechSynthesis.retranslatedTitle) {
      speechSynthesis.retranslatedTitle = '';
    }
    speechSynthesis.isChangeTitle = true;
  }

  /**
   * edit Speech Synthesis
   * @param speechSynthesis
   * @param event
   * @returns
   */
  public editTranslatedText(speechSynthesis: SpeechSynthesis, event: any): void {
    if (!event) {
      event.preventDefault();
      return;
    }
    if (!speechSynthesis.isEditText) {
      speechSynthesis.isEditText = !speechSynthesis.isEditText;
    }
    if (event.keyCode == Constant.ENTER_KEY_CODE && speechSynthesis.isEditText) {
      let payload = {
        source_language_code: speechSynthesis.translationLanguageCode,
        text: Helper.encodeHTMLAnnouncementManager(speechSynthesis.translatedText),
        target_language_code: this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey,
        glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_push_nortification` : ''
      };

      if (!speechSynthesis.retranslatedText && speechSynthesis.translatedText.trim().length) {
        this.announcementManagerService.singleRetranslation(payload).subscribe(res => {
          const data = res['message'];
          speechSynthesis.retranslatedText = Helper.reEncodeHTMLAnnouncementManager(data['retranslated_text']);
        });
      }
      speechSynthesis.isEditText = !speechSynthesis.isEditText;
    }
  }

  /**
   * change Speech Text
   * @param speechSynthesis
   */
  public changeTranslatedText(speechSynthesis: SpeechSynthesis): void {
    if (speechSynthesis.retranslatedText) {
      speechSynthesis.retranslatedText = '';
    }
    speechSynthesis.isChangeText = true;
  }

  /**
   * check translation
   * @param device translation selected
   */
  changeCheckedTranslation(speechSynthesis: SpeechSynthesis) {
    speechSynthesis.isChecked = !speechSynthesis.isChecked;
    this.isCheckedAllLanguage = this.listSpeechSynthesis.filter(e => e.translationLanguageCode).every(e => e.isChecked);
  }

  /**
   * getTitleAndText
   * @param notification
   * @returns
   */
  getTitleAndText(notificationTitle: any, originalLanguage: any): string {
    if (!notificationTitle) {
      return '';
    }
    if (!notificationTitle[originalLanguage]) {
      return Object.values(notificationTitle)[0].toString() ?? '';
    }
    return notificationTitle[originalLanguage];
  }

  /**
   * fillTitleAndText
   */
  fillTitleAndText(notification: any): void {
    if (
      !notification ||
      !notification.notificationTitle ||
      !notification.notificationBody ||
      !this.listSpeechSynthesis ||
      !this.listSpeechSynthesis.length
    ) {
      return;
    }
    let languageMappings = [];
    this.languagesTranslation.forEach(e => {
      if (notification.notificationTitle.hasOwnProperty(e.translation_language_code) && e.translation_language_code != this.languageCode) {
        languageMappings.push(e.translation_language_code);
      }
    });
    languageMappings = Helper.getLanguagesCode(languageMappings, this.commonService);
    if (notification.notificationTitle.hasOwnProperty(this.languageCode)) {
      this.listSpeechSynthesis[0].translationLanguageCode = this.languageCode;
      this.listSpeechSynthesis[0].translatedTitle = notification.notificationTitle[this.languageCode];
      this.listSpeechSynthesis[0].translatedText = notification.notificationBody[this.languageCode];
      //retranslatedTitle
      let payloadTitle = {
        source_language_code: this.listSpeechSynthesis[0].translationLanguageCode,
        text: Helper.encodeHTMLAnnouncementManager(this.listSpeechSynthesis[0].translatedTitle),
        target_language_code: this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey,
        glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_push_nortification` : ''
      };

      this.announcementManagerService.singleRetranslation(payloadTitle).subscribe(res1 => {
        const data1 = res1['message'];
        this.listSpeechSynthesis[0].retranslatedTitle = Helper.reEncodeHTMLAnnouncementManager(data1['retranslated_text']);
      });

      //retranslatedText
      let payloadText = {
        source_language_code: this.listSpeechSynthesis[0].translationLanguageCode,
        text: Helper.encodeHTMLAnnouncementManager(this.listSpeechSynthesis[0].translatedText),
        target_language_code: this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey,
        glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_push_nortification` : ''
      };

      this.announcementManagerService.singleRetranslation(payloadText).subscribe(res2 => {
        const data2 = res2['message'];
        this.listSpeechSynthesis[0].retranslatedText = Helper.reEncodeHTMLAnnouncementManager(data2['retranslated_text']);
      });
    }

    languageMappings.forEach((e, index) => {
      this.listSpeechSynthesis[index + 1].translationLanguageCode = e;
      this.changeLanguage(this.listSpeechSynthesis[index + 1]);
      this.listSpeechSynthesis[index + 1].translatedTitle = notification.notificationTitle[e];
      this.listSpeechSynthesis[index + 1].translatedText = notification.notificationBody[e];
      this.listActive.add(e);

      //retranslatedTitle
      let payloadTitle = {
        source_language_code: this.listSpeechSynthesis[index + 1].translationLanguageCode,
        text: Helper.encodeHTMLAnnouncementManager(this.listSpeechSynthesis[index + 1].translatedTitle),
        target_language_code: this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey,
        glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_push_nortification` : ''
      };

      this.announcementManagerService.singleRetranslation(payloadTitle).subscribe(res1 => {
        const data1 = res1['message'];
        this.listSpeechSynthesis[index + 1].retranslatedTitle = Helper.reEncodeHTMLAnnouncementManager(data1['retranslated_text']);
      });

      //retranslatedText
      let payloadText = {
        source_language_code: this.listSpeechSynthesis[index + 1].translationLanguageCode,
        text: Helper.encodeHTMLAnnouncementManager(this.listSpeechSynthesis[index + 1].translatedText),
        target_language_code: this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey,
        glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_push_nortification` : ''
      };

      this.announcementManagerService.singleRetranslation(payloadText).subscribe(res2 => {
        const data2 = res2['message'];
        this.listSpeechSynthesis[index + 1].retranslatedText = Helper.reEncodeHTMLAnnouncementManager(data2['retranslated_text']);
      });
    });

    let commonTableSave = new CommonTable();
    commonTableSave.key = Constant.KEY_LANGUAGE_PUSH_NOTIFICATION;
    commonTableSave.value = JSON.stringify(languageMappings);
    this.dataService.sendData([Constant.IS_SAVING_LANGUAGE_PUSH_NOTIFICATION, true]);
    this.commonTableService.save(commonTableSave).subscribe(
      data => {
        this.dataService.sendData([Constant.IS_SAVING_LANGUAGE_PUSH_NOTIFICATION, false]);
      },
      error => {
        this.dataService.sendData([Constant.IS_SAVING_LANGUAGE_PUSH_NOTIFICATION, false]);
      }
    );
  }

  /**
   * getDate
   * @param input
   * @returns
   */
  getDate(input: string): string {
    if (!input) {
      return '';
    }
    return Helper.convertTimeToTimeCMP(input, this.commonObject, 'YYYY-MM-DD HH:mm:ss');
  }

  /**
   * getStatus
   */
  getStatus(input: string): string {
    if (!input) {
      return '';
    }
    if (input == 'succeeded') {
      return this.languageKey == 'en' ? 'Success' : '成功';
    } else {
      return this.languageKey == 'en' ? 'Failure' : '失敗';
    }
  }

  /**
   * selectNotification
   */
  selectNotification(notification: any): void {
    if (!notification) {
      return;
    }
    this.notificationSelected = notification;
  }

  /**
   * confirmation
   * @param notification
   * @returns
   */
  confirmation(notification: any) {
    if (!notification) {
      return;
    }
    const hasData = this.listSpeechSynthesis.some(e => e.translatedTitle || e.translatedText);

    if (this.translation.title || this.translation.text || hasData) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('fuji-climber-management.tab-push-notification.confirm-confirmation'),
            button1: this.translateService.instant('fuji-climber-management.tab-push-notification.ok'),
            button2: this.translateService.instant('fuji-climber-management.tab-push-notification.no')
          }
        },
        data => {
          if (!data) {
            return;
          }
          this.clearData();
          this.translation.title = this.getTitleAndText(notification.notificationTitle, notification.originalLanguage);
          this.translation.text = this.getTitleAndText(notification.notificationBody, notification.originalLanguage);
          this.fillTitleAndText(notification);
        }
      );
    } else {
      this.clearData();
      this.translation.title = this.getTitleAndText(notification.notificationTitle, notification.originalLanguage);
      this.translation.text = this.getTitleAndText(notification.notificationBody, notification.originalLanguage);
      this.fillTitleAndText(notification);
    }
  }

  /**
   * clearData
   */
  clearData() {
    this.isCheckedAllLanguage = false;
    this.translation.title = '';
    this.translation.text = '';
    this.listActive.clear();
    this.listActive.add(this.languageCode);
    this.listSpeechSynthesis.forEach((e, index) => {
      if (index != 0) {
        e.translationLanguageCode = '';
      }
      e.translatedTitle = '';
      e.translatedText = '';
      e.retranslatedTitle = '';
      e.retranslatedText = '';
      e.isChecked = false;
    });
  }

  /**
   * saveLanguageToCommonTable
   */
  saveLanguageToCommonTable(): void {
    const language = [];
    this.listSpeechSynthesis.forEach((e, index) => {
      if (index != 0 && e.translationLanguageCode) {
        language.push(e.translationLanguageCode);
      }
    });
    let commonTableSave = new CommonTable();
    commonTableSave.key = Constant.KEY_LANGUAGE_PUSH_NOTIFICATION;
    commonTableSave.value = JSON.stringify(language);
    this.dataService.sendData([Constant.IS_SAVING_LANGUAGE_PUSH_NOTIFICATION, true]);
    this.commonTableService.save(commonTableSave).subscribe(
      data => {
        this.dataService.sendData([Constant.IS_SAVING_LANGUAGE_PUSH_NOTIFICATION, false]);
      },
      error => {
        this.dataService.sendData([Constant.IS_SAVING_LANGUAGE_PUSH_NOTIFICATION, false]);
      }
    );
  }
}

declare const ResizeObserver: any;
