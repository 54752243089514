import { Component, OnInit } from '@angular/core';
import { Constant } from 'app/config/constants';
import { DataService } from 'app/service/data.service';
import { FujiClimberManagementService } from 'app/service/fuji-climber-management.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
@Component({
  selector: 'fuji-climber-management',
  templateUrl: './fuji-climber-management.component.html',
  styleUrls: ['./fuji-climber-management.component.scss']
})
export class FujiClimberManagementComponent implements OnInit {
  /**
   * Constant
   */
  Constant = Constant;

  /**
   * tab selected
   */
  tabSelected: number = Constant.CLIMBER_LOCATION_INFORMATION_ENUM;
  informationAccount: any;

  constructor(
    private ticketService: TicketEditorService,
    private fujiClimberManagementService: FujiClimberManagementService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.ticketService.getInformationAccount().subscribe(async data => {
      this.informationAccount = data;
      this.fujiClimberManagementService.setInformationAccount(this.informationAccount);
      this.dataService.sendData([Constant.IS_TAB_CLIMBER_LOCATION_INFORMATION, true]);
    });
  }

  /**
   * choose tab
   * @param tabEnum
   */
  public chooseTab(tabEnum: any): void {
    if (this.tabSelected == tabEnum) {
      return;
    }
    this.tabSelected = tabEnum;
    this.dataService.sendData([Constant.IS_TAB_CLIMBER_LOCATION_INFORMATION, tabEnum == Constant.CLIMBER_LOCATION_INFORMATION_ENUM]);
    this.dataService.sendData([Constant.IS_TAB_PUSH_NOTIFICATION, tabEnum == Constant.PUSH_NOTIFICATION_ENUM]);
  }
}
