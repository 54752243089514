import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import { Helper } from 'app/common/helper';
import {
  Constant,
  ErrorEnum,
  FIELD_COMPONENT,
  MODULE_NAME,
  NewsContentSourceEnum,
  ObjectFitEnum,
  PreviewToolEnum,
  WeatherContentSourceEnum
} from 'app/config/constants';
import { DialogCallApiCycleComponent } from 'app/dialog/dialog-call-api-cycle/dialog-call-api-cycle.component';
import { DialogChangeTemplateForExternalContentComponent } from 'app/dialog/dialog-change-template-for-external-content/dialog-change-template-for-external-content.component';
import { DialogChooseCityComponent } from 'app/dialog/dialog-choose-city/dialog-choose-city.component';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogSettingAutoUpdateComponent } from 'app/dialog/dialog-setting-auto-update/dialog-setting-auto-update.component';
import { APICycle } from 'app/model/entity/api-cycle';
import { APIDetails } from 'app/model/entity/api-details';
import { Area } from 'app/model/entity/area';
import { City } from 'app/model/entity/city';
import { Common } from 'app/model/entity/common';
import { CommonTable } from 'app/model/entity/commonTable';
import { IndexWordGroup } from 'app/model/entity/index-word-group';
import { NewsContent } from 'app/model/entity/news-content';
import { OpenWeatherContent } from 'app/model/entity/open-weather-content';
import { OpenWeatherContentDetail } from 'app/model/entity/open-weather-content-detail';
import { PictureArea } from 'app/model/entity/picture-area';
import { Template } from 'app/model/entity/template';
import { TextArea } from 'app/model/entity/text-area';
import { WeatherContent } from 'app/model/entity/weather-content';
import { WeatherContentDetail } from 'app/model/entity/weather-content-detail';
import { SaveExternalContentManagerStateAction } from 'app/ngrx-component-state-management/component-state.action';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { DrawService } from 'app/service/draw.service';
import { ExecutingService } from 'app/service/executing.service';
import { IndexWordGroupService } from 'app/service/index-word-group.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { MunicipalService } from 'app/service/municipal.service';
import { NewsContentDetailService } from 'app/service/news-content-detail.service';
import { NewsContentService } from 'app/service/news-content.service';
import { OpenWeatherContentService } from 'app/service/open-weather-content.service';
import { WeatherContentDetailService } from 'app/service/weather-content-detail.service';
import { WeatherContentService } from 'app/service/weather-content.service';
import { AppState } from 'app/store/app.state';
import * as fileSaver from 'file-saver';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject, interval, timer } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { repeatWhen, startWith, switchMap, takeUntil, timeInterval } from 'rxjs/operators';
import { DialogApiDetailsComponent } from './../../dialog/dialog-api-details/dialog-api-details.component';
import {
  SaveNewsContentTabStateAction,
  SaveOpenWeatherContentTabStateAction,
  SaveWeatherContentTabStateAction
} from './ngrx/content-action';
import { CustomSelectBoxComponent } from '../custom-component/custom-select-box/custom-select-box.component';
@Component({
  selector: 'external-content-manager',
  templateUrl: './external-content-manager.component.html',
  styleUrls: ['./external-content-manager.component.scss']
})
export class ExternalContentManagerComponent implements OnInit, OnDestroy {
  @ViewChildren('customSelectBox') selectBoxes: QueryList<CustomSelectBoxComponent>;
  //#region public global var
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  WeatherContentSourceEnum = WeatherContentSourceEnum;
  NewsContentSourceEnum = NewsContentSourceEnum;
  public readonly MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME = 64;
  public readonly MAX_LENGTH_MUNICIPAL_CODE = 10;
  public readonly MIN_PAGE_COUNTS = 1;
  public readonly MAX_PAGE_COUNTS = 10;
  public readonly MIN_DURATION = 1;
  public readonly MAX_DURATION = 60;
  public readonly MIN_FORECAST_PARAM = 1;
  public readonly MIN_OPEN_FORECAST_PARAM = 0;
  public readonly MAX_FORECAST_PARAM = 48;
  public readonly MAX_OPEN_FORECAST_PARAM = 47;
  public readonly MAX_OPEN_FORECAST_PARAM_DAILY = 7;
  public readonly linkTextWeatherSourceOptions = [
    { id: WeatherContentSourceEnum.WEATHER_LOCATION, name: 'weather_location' },
    { id: WeatherContentSourceEnum.DATE_TIME_MM_DD, name: 'date_time (MM/DD)' },
    { id: WeatherContentSourceEnum.DATE_TIME_HH, name: 'date_time (HH)' },
    { id: WeatherContentSourceEnum.TEMP, name: 'temp' }
  ];
  linkTextOpenWeatherSourceOptions = [
    { id: 0, name: this.translateService.instant('external-content-manager.source-open-weather.month') },
    { id: 1, name: this.translateService.instant('external-content-manager.source-open-weather.day') },
    { id: 2, name: this.translateService.instant('external-content-manager.source-open-weather.time') },
    { id: 3, name: this.translateService.instant('external-content-manager.source-open-weather.temperature') },
    { id: 4, name: this.translateService.instant('external-content-manager.source-open-weather.humidity') },
    { id: 5, name: this.translateService.instant('external-content-manager.source-open-weather.chance-of-rain') },
    { id: 6, name: this.translateService.instant('external-content-manager.source-open-weather.wind-speed') }
  ];
  linkTextOpenWeatherSourceOfDayOptions = [
    { id: 0, name: this.translateService.instant('external-content-manager.source-open-weather.month') },
    { id: 1, name: this.translateService.instant('external-content-manager.source-open-weather.day') },
    { id: 8, name: this.translateService.instant('external-content-manager.source-open-weather.day-of-week') },
    { id: 4, name: this.translateService.instant('external-content-manager.source-open-weather.humidity') },
    { id: 5, name: this.translateService.instant('external-content-manager.source-open-weather.chance-of-rain') },
    { id: 6, name: this.translateService.instant('external-content-manager.source-open-weather.wind-speed') },
    { id: 9, name: this.translateService.instant('external-content-manager.source-open-weather.max-temperature') },
    { id: 10, name: this.translateService.instant('external-content-manager.source-open-weather.min-temperature') }
  ];

  linkTextIntervals = [
    { id: 0, name: this.translateService.instant('external-content-manager.hourly') },
    { id: 1, name: this.translateService.instant('external-content-manager.daily') }
  ];

  public readonly linkPictureWeatherSourceOptions = [{ id: WeatherContentSourceEnum.WEATHER_NO, name: 'weather_no' }];
  linkPictureOpenWeatherSourceofDayOptions = [
    { id: 7, name: this.translateService.instant('external-content-manager.source-open-weather.weather') },
    { id: 8, name: this.translateService.instant('external-content-manager.source-open-weather.day-of-week') }
  ];
  linkPictureOpenWeatherSourceOptions = [
    { id: 7, name: this.translateService.instant('external-content-manager.source-open-weather.weather') },
    { id: 2, name: this.translateService.instant('external-content-manager.source-open-weather.time') }
  ];
  public readonly newsSourceOptions = [
    { id: NewsContentSourceEnum.TITLE, name: 'Title' },
    { id: NewsContentSourceEnum.CONTENT_TYPE, name: 'Content type="text/plan"' }
  ];
  public indexWordGroups: Array<IndexWordGroup>;
  public contents: Array<any>;
  public isActiveNewsTab: boolean;
  public isActiveWeatherTab: boolean;
  public isEnlargePreview: boolean = false;
  public isChangeMunicipalCode: boolean = false;
  public contentSelected: any;
  public contentDetailSelected: any;
  public areas: Array<any>;
  public isReloadContentData: boolean = false;
  public isPlayPreview: boolean = false;
  public newsCurrentPage: number = 1;
  public newsTotalPages: number;
  public areaDisabledOptions: Array<Area>;
  public cityListFromImport: Array<City>;
  public accountId: string;
  public offsetToTimezone: { [key: string]: string } = {
    '0': 'Europe/London', // UTC+0
    '1': 'Europe/Paris', // UTC+1
    '2': 'Europe/Athens', // UTC+2
    '3': 'Europe/Moscow', // UTC+3
    '4': 'Asia/Dubai', // UTC+4
    '5': 'Asia/Karachi', // UTC+5
    '5.5': 'Asia/Kolkata', // UTC+5:30
    '6': 'Asia/Dhaka', // UTC+6
    '7': 'Asia/Bangkok', // UTC+7
    '8': 'Asia/Shanghai', // UTC+8
    '9': 'Asia/Tokyo', // UTC+9
    '10': 'Australia/Sydney', // UTC+10
    '11': 'Pacific/Noumea', // UTC+11
    '12': 'Pacific/Auckland', // UTC+12
    '13': 'Pacific/Apia', // UTC+13
    '-1': 'Atlantic/Azores', // UTC-1
    '-2': 'Atlantic/South_Georgia', // UTC-2
    '-3': 'America/Sao_Paulo', // UTC-3
    '-4': 'America/New_York', // UTC-4
    '-5': 'America/Chicago', // UTC-5
    '-6': 'America/Denver', // UTC-6
    '-7': 'America/Los_Angeles', // UTC-7
    '-8': 'Pacific/Pitcairn', // UTC-8
    '-9': 'America/Anchorage', // UTC-9
    '-10': 'Pacific/Honolulu', // UTC-10
    '-11': 'Pacific/Niue', // UTC-11
    '-12': 'Pacific/Baker_Island' // UTC-12
  };

  private isCheckLoadOpenWeatherTab: boolean;
  @ViewChild('openWeatherTab') myDiv: ElementRef<HTMLElement>;
  //#endregion

  //#region private global var
  private readonly ENTER_KEY_CODE = 13;
  private readonly highlightAreaPreviewSubject = new Subject();
  private readonly resetTimerPreviewSubject = new Subject();
  private readonly pausePreviewSubject = new Subject();
  private readonly pauseAutoCallApiSubject = new Subject<void>();
  private readonly startAutoCallApiSubject = new Subject<void>();
  timeOutBeginStartCall: any;
  timeOutEndCall: any;
  private readonly propertiesName = {
    content: 'Content',
    contentOutputFileName: 'Content Output File Name',
    municipalCode: 'Municipal Code',
    pageCounts: 'Page Counts',
    duration: 'Duration',
    forecastParam: 'Forecast Param'
  };
  private subscriptions: Array<Subscription> = new Array<Subscription>();
  private inputFocusCurrent: any;
  private contentCloneSelected: any;
  private stateOfComponent: {
    isChangeLayout: boolean;
    indexWordGroups: Array<IndexWordGroup>;
    contents: Array<any>;
    isActiveNewsTab: boolean;
    isActiveWeatherTab: boolean;
    isEnlargePreview: boolean;
    isChangeMunicipalCode: boolean;
    contentSelected: any;
    contentDetailSelected: any;
    areas: Array<any>;
    newsCurrentPage: number;
    areaDisabledOptions: Array<Area>;
    inputFocusCurrent: any;
    contentCloneSelected: any;
    isChangedData: boolean;
    isChangedNewsTab: boolean;
    isChangedWeatherTab: boolean;
    isChangedOpenWeatherTab: boolean;
    intervalCallApi: any;
    cityListFromImport: any;
    isReloadContentData: boolean;
    isPlayPreview: boolean;
    newsTotalPages: number;
    isCheckLoadOpenWeatherTab: boolean;
    timeOutBeginStartCall: any;
    timeOutEndCall: any;
    apiDetails: APIDetails;
    apiCycle: any;
    isChangeTab: boolean;
    isZoom: boolean;
    isPan: boolean;
  };
  private newsContentTabStateOfComponent: {
    isChangeTab: boolean;
    contents: Array<any>;
    contentSelected: any;
    contentDetailSelected: any;
    areas: Array<any>;
    newsCurrentPage: number;
    areaDisabledOptions: Array<Area>;
    inputFocusCurrent: any;
    contentCloneSelected: any;
  };
  private weatherContentTabStateOfComponent: {
    isChangeTab: boolean;
    indexWordGroups: Array<IndexWordGroup>;
    contents: Array<any>;
    isChangeMunicipalCode: boolean;
    contentSelected: any;
    contentDetailSelected: any;
    areas: Array<any>;
    areaDisabledOptions: Array<Area>;
    inputFocusCurrent: any;
    contentCloneSelected: any;
  };
  private openWeatherContentTabStateOfComponent: {
    isChangeTab: boolean;
    indexWordGroups: Array<IndexWordGroup>;
    contents: Array<any>;
    contentSelected: any;
    contentDetailSelected: any;
    areas: Array<any>;
    areaDisabledOptions: Array<Area>;
    inputFocusCurrent: any;
    contentCloneSelected: any;
    intervalCallApi: any;
    cityListFromImport: any;
  };
  private isChangedData: boolean;
  private isChangedNewsTab: boolean;
  /**
   * Common object
   */
  private commonObject: Common;
  /**
   * isRoot
   */
  public isRoot: boolean;
  public isChangedWeatherTab: boolean;
  public isChangedOpenWeatherTab: boolean;
  private apiDetails: APIDetails;
  private apiCycle: any;
  /**
   * keys code
   */
  private keyCodeMap = {
    17: false, // Ctrl
    18: false, // Alt
    83: false // S
  };

  intervalCallApi: any;

  /**
   * language key
   */
  public languageKey: string;

  /**
   * true if active zoom
   */
  public isZoom: boolean;
  /**
   * true if active pan
   */
  public isPan: boolean;

  /**
   * PreviewToolEnum
   */
  public readonly PreviewToolEnum = PreviewToolEnum;

  /**
   * panzoomDisplay1
   */
  private panzoomDisplay: PanzoomObject;

  //#endregion

  //#region view child element
  @ViewChild('selectElement', { static: false })
  selectElement: ElementRef;

  @ViewChild('selectDetailedElement', { static: false })
  selectDetailedElement: ElementRef;

  @ViewChild('divContainCanvas', { static: false })
  divContainCanvas: ElementRef;

  @ViewChild('contentOutputInput', { static: false })
  contentOutputInput: ElementRef;

  @ViewChild('municipalCodeInput', { static: false })
  municipalCodeInput: ElementRef;

  @ViewChild('pageCountsInput', { static: false })
  pageCountsInput: ElementRef;

  @ViewChild('durationInput', { static: false })
  durationInput: ElementRef;

  @ViewChild('forecastParamInput', { static: false })
  forecastParamInput: ElementRef;

  @ViewChild('divPreview', { static: false })
  divPreview: ElementRef;

  @ViewChild('ldsRing', { static: false })
  ldsRing: ElementRef;

  @ViewChild('divNewsPages', { static: false })
  divNewsPages: ElementRef;
  //#endregion

  /**
   * save data success
   */
  @Output() saveDataSuccess = new EventEmitter<boolean>();

  isDayMode: boolean;

  constructor(
    private indexWordGroupService: IndexWordGroupService,
    private actionService: MenuActionService,
    private dialogService: DialogService,
    private newsContentService: NewsContentService,
    private newsContentDetailService: NewsContentDetailService,
    private weatherContentService: WeatherContentService,
    private openWeatherContentService: OpenWeatherContentService,
    private weatherContentDetailService: WeatherContentDetailService,
    private municipalService: MunicipalService,
    private renderer: Renderer2,
    private toastService: ToastrService,
    private dataService: DataService,
    private drawService: DrawService,
    private readonly store: Store<AppState>,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private commonService: CommonService,
    private executingService: ExecutingService,
    private commonTableService: CommonTableService
  ) {
    // subscribe action
    this.subscriptions.push(
      this.actionService.actionAdd.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.addContent();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionEdit.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.editContent();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionDelete.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          if (!this.contentDetailSelected) {
            this.deleteContent();
          } else if (this.contentDetailSelected && !this.isActiveNewsTab) {
            this.deleteContentDetail();
          }
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionChangeTemplate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.changeTemplate();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionSave.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          if (this.contentSelected?.isEdit) {
            this.saveEditContent(this.isChangedNewsTab || this.isChangedWeatherTab || this.isChangedOpenWeatherTab);
          } else {
            this.saveBeforeLeave();
          }
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionSaveContentDetail.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.save();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionCreateOutputFile.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.createOutputFile();
        }
      })
    );

    this.subscriptions.push(
      this.actionService.actionSetAPICallCycle.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.setAPICallCycle();
        }
      })
    );

    this.subscriptions.push(
      this.actionService.actionSetAPIDetails.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.setAPIDetails();
        }
      })
    );

    this.subscriptions.push(
      this.actionService.actionImportCity.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.importCity();
        }
      })
    );

    this.subscriptions.push(
      this.actionService.actionExportCityFormat.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.exportCityFormat();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionChooseCity.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.chooseCity();
        }
      })
    );
    this.subscriptions.push(
      this.actionService.actionSettingAutoUpdate.subscribe(moduleName => {
        if (moduleName == MODULE_NAME[FIELD_COMPONENT.ExternalContentManagerComponent]) {
          this.settingAutoUpdate();
        }
      })
    );
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.linkTextOpenWeatherSourceOptions = [
          { id: 0, name: this.translateService.instant('external-content-manager.source-open-weather.month') },
          { id: 1, name: this.translateService.instant('external-content-manager.source-open-weather.day') },
          { id: 2, name: this.translateService.instant('external-content-manager.source-open-weather.time') },
          { id: 3, name: this.translateService.instant('external-content-manager.source-open-weather.temperature') },
          { id: 4, name: this.translateService.instant('external-content-manager.source-open-weather.humidity') },
          { id: 5, name: this.translateService.instant('external-content-manager.source-open-weather.chance-of-rain') },
          { id: 6, name: this.translateService.instant('external-content-manager.source-open-weather.wind-speed') }
        ];
        this.linkTextOpenWeatherSourceOfDayOptions = [
          { id: 0, name: this.translateService.instant('external-content-manager.source-open-weather.month') },
          { id: 1, name: this.translateService.instant('external-content-manager.source-open-weather.day') },
          { id: 8, name: this.translateService.instant('external-content-manager.source-open-weather.day-of-week') },
          { id: 4, name: this.translateService.instant('external-content-manager.source-open-weather.humidity') },
          { id: 5, name: this.translateService.instant('external-content-manager.source-open-weather.chance-of-rain') },
          { id: 6, name: this.translateService.instant('external-content-manager.source-open-weather.wind-speed') },
          { id: 9, name: this.translateService.instant('external-content-manager.source-open-weather.max-temperature') },
          { id: 10, name: this.translateService.instant('external-content-manager.source-open-weather.min-temperature') }
        ];
        this.linkPictureOpenWeatherSourceOptions = [
          { id: 7, name: this.translateService.instant('external-content-manager.source-open-weather.weather') }
        ];
        this.linkTextIntervals = [
          { id: 0, name: this.translateService.instant('external-content-manager.hourly') },
          { id: 1, name: this.translateService.instant('external-content-manager.daily') }
        ];
        this.linkPictureOpenWeatherSourceofDayOptions = [
          { id: 7, name: this.translateService.instant('external-content-manager.source-open-weather.weather') },
          { id: 8, name: this.translateService.instant('external-content-manager.source-open-weather.day-of-week') }
        ];
        this.linkPictureOpenWeatherSourceOptions = [
          { id: 7, name: this.translateService.instant('external-content-manager.source-open-weather.weather') },
          { id: 2, name: this.translateService.instant('external-content-manager.source-open-weather.time') }
        ];
      })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((contentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: contentState?.externalContentManagerState?.stateOfComponent.isChangeLayout,
            contents: contentState?.externalContentManagerState?.stateOfComponent.contents,
            indexWordGroups: contentState?.externalContentManagerState?.stateOfComponent.indexWordGroups,
            isActiveNewsTab: contentState?.externalContentManagerState?.stateOfComponent.isActiveNewsTab,
            isActiveWeatherTab: contentState?.externalContentManagerState?.stateOfComponent.isActiveWeatherTab,
            isEnlargePreview: contentState?.externalContentManagerState?.stateOfComponent.isEnlargePreview,
            isChangeMunicipalCode: contentState?.externalContentManagerState?.stateOfComponent.isChangeMunicipalCode,
            contentSelected: contentState?.externalContentManagerState?.stateOfComponent.contentSelected,
            contentDetailSelected: contentState?.externalContentManagerState?.stateOfComponent.contentDetailSelected,
            areas: contentState?.externalContentManagerState?.stateOfComponent.areas,
            newsCurrentPage: contentState?.externalContentManagerState?.stateOfComponent.newsCurrentPage,
            areaDisabledOptions: contentState?.externalContentManagerState?.stateOfComponent.areaDisabledOptions,
            inputFocusCurrent: contentState?.externalContentManagerState?.stateOfComponent.inputFocusCurrent,
            contentCloneSelected: contentState?.externalContentManagerState?.stateOfComponent.contentCloneSelected,
            isChangedData: contentState?.externalContentManagerState?.stateOfComponent.isChangedData,
            isChangedNewsTab: contentState?.externalContentManagerState?.stateOfComponent.isChangedNewsTab,
            isChangedWeatherTab: contentState?.externalContentManagerState?.stateOfComponent.isChangedWeatherTab,
            isChangedOpenWeatherTab: contentState?.externalContentManagerState?.stateOfComponent.isChangedOpenWeatherTab,
            intervalCallApi: contentState?.externalContentManagerState?.stateOfComponent.intervalCallApi,
            cityListFromImport: contentState?.externalContentManagerState?.stateOfComponent.cityListFromImport,
            isReloadContentData: contentState?.externalContentManagerState?.stateOfComponent.isReloadContentData,
            isPlayPreview: contentState?.externalContentManagerState?.stateOfComponent.isPlayPreview,
            newsTotalPages: contentState?.externalContentManagerState?.stateOfComponent.newsTotalPages,
            isCheckLoadOpenWeatherTab: contentState?.externalContentManagerState?.stateOfComponent.isCheckLoadOpenWeatherTab,
            timeOutBeginStartCall: contentState?.externalContentManagerState?.stateOfComponent.timeOutBeginStartCall,
            timeOutEndCall: contentState?.externalContentManagerState?.stateOfComponent.timeOutEndCall,
            apiDetails: contentState?.externalContentManagerState?.stateOfComponent.apiDetails,
            apiCycle: contentState?.externalContentManagerState?.stateOfComponent.apiCycle,
            isChangeTab: contentState?.externalContentManagerState?.stateOfComponent.isChangeTab,
            isZoom: contentState?.externalContentManagerState?.stateOfComponent.isZoom,
            isPan: contentState?.externalContentManagerState?.stateOfComponent.isPan
          };
        })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((newsContent: any) => {
          this.newsContentTabStateOfComponent = {
            isChangeTab: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.isChangeTab,
            contents: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.contents,
            contentSelected: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.contentSelected,
            contentDetailSelected: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.contentDetailSelected,
            areas: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.areas,
            newsCurrentPage: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.newsCurrentPage,
            areaDisabledOptions: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.areaDisabledOptions,
            inputFocusCurrent: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.inputFocusCurrent,
            contentCloneSelected: newsContent?.newsContentTabState?.newsContentTabStateOfComponent.contentCloneSelected
          };
        })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((weatherContent: any) => {
          this.weatherContentTabStateOfComponent = {
            isChangeTab: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.isChangeTab,
            indexWordGroups: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.indexWordGroups,
            contents: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.contents,
            isChangeMunicipalCode: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.isChangeMunicipalCode,
            contentSelected: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.contentSelected,
            contentDetailSelected: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.contentDetailSelected,
            areas: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.areas,
            areaDisabledOptions: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.areaDisabledOptions,
            inputFocusCurrent: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.inputFocusCurrent,
            contentCloneSelected: weatherContent?.weatherContentTabState?.weatherContentTabStateOfComponent.contentCloneSelected
          };
        })
    );
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((openWeatherContent: any) => {
          this.openWeatherContentTabStateOfComponent = {
            isChangeTab: openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.isChangeTab,
            indexWordGroups: openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.indexWordGroups,
            contents: openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.contents,
            contentSelected: openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.contentSelected,
            contentDetailSelected:
              openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.contentDetailSelected,
            areas: openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.areas,
            areaDisabledOptions: openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.areaDisabledOptions,
            inputFocusCurrent: openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.inputFocusCurrent,
            contentCloneSelected:
              openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.contentCloneSelected,
            intervalCallApi: openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.intervalCallApi,
            cityListFromImport: openWeatherContent?.openWeatherContentTabState?.openWeatherContentTabStateOfComponent.cityListFromImport
          };
        })
    );
    this.pausePreviewSubject.subscribe(() => {
      this.isPlayPreview = false;
    });
    this.commonObject = this.commonService.getCommonObject();
    if (this.commonObject.userIdString == 'root') {
      this.isRoot = true;
      this.isActiveNewsTab = true;
    }

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language;
      })
    );
  }

  /**
   * on init
   */
  async ngOnInit() {
    if (!this.stateOfComponent?.isChangeLayout) {
      this.executingService.executing();
      await Helper.loadFontsToPreview(this.store, this.commonObject, this.translateService, this.dialogService);
      this.executingService.executed();
      await this.getInforAPI();
      if (this.isRoot) {
        this.isActiveNewsTab = this.commonObject?.isActiveNewsTab === undefined ? true : this.commonObject.isActiveNewsTab;
        this.isActiveWeatherTab = this.commonObject?.isActiveWeatherTab === undefined ? true : this.commonObject.isActiveWeatherTab;
      }
      // this.dataService.sendData([Constant.IS_TAB_OPEN_WEATHER, true]);
      if (this.isActiveNewsTab) {
        this.chooseNewsTab();
      } else if (this.isActiveWeatherTab) {
        this.chooseWeatherTab();
      } else {
        this.chooseOpenWeatherTab();
      }
    } else {
      this.handleSetDataForComponentAfterChangeLayout();
    }
    this.languageKey = this.commonObject?.setting?.language;
    this.accountId = this.commonObject.tenantName.toUpperCase();
  }

  /**
   * confirmSaveOpenWeather
   */
  public confirmSaveOpenWeather(isActiveWeatherTab: boolean) {
    if (this.isChangedOpenWeatherTab || this.contentSelected?.isEdit) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('layout.confirm-save'),
            button1: this.translateService.instant('layout.yes'),
            button2: this.translateService.instant('layout.no')
          }
        },
        result => {
          if (!result) {
            this.isChangedOpenWeatherTab = false;
            this.contentSelected.isEdit = false;
            this.startActionChooseTab(isActiveWeatherTab);
          } else {
            if (this.contentSelected?.isEdit) {
              this.saveEditContent(false);
            }
            if (this.isChangedOpenWeatherTab) {
              this.save();
            }
            this.startActionChooseTab(isActiveWeatherTab);
          }
        }
      );
    } else {
      this.startActionChooseTab(isActiveWeatherTab);
    }
  }
  /**
   * getInforAPI
   * @returns
   */
  private async getInforAPI(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.commonTableService.getValueCommonTableByKey(Constant.KEY_API_DETAILS).subscribe(
        result => {
          if (result) {
            this.apiDetails = JSON.parse(result.value);
          }
          this.commonTableService.getValueCommonTableByKey(Constant.KEY_CALL_API_CYCLE).subscribe(
            result => {
              if (result) {
                this.apiCycle = JSON.parse(result.value);
              }
              resolve();
            },
            error => reject()
          );
        },
        error => reject()
      );
    });
  }
  /**
   *
   * @param isFirstCall
   * @param isCallAll
   */
  private autoCallAPIWeather(isCallAll: boolean): void {
    const timeCall = this.apiCycle.interval * 60 * 60 * 1000;
    this.openWeatherContentService.autoCallAPIWeather(this.apiDetails, isCallAll, -1).subscribe(data => {
      const indexSelected = this.contents.findIndex(content => content.id == this.contentSelected.id);
      const timeOut = interval(timeCall);
      this.intervalCallApi?.unsubscribe();
      this.contents = Helper.convertDataOpenWeatherContents(data);
      if (!this.contents || this.contents.length == 0) {
        this.dataService.sendData([Constant.HAS_CONTENT, false]);
      } else {
        this.dataService.sendData([Constant.HAS_CONTENT, true]);
      }
      this.selectContent(this.contents[indexSelected]);
      this.intervalCallApi = timeOut
        .pipe(
          timeInterval(),
          takeUntil(this.pauseAutoCallApiSubject),
          repeatWhen(() => this.startAutoCallApiSubject)
        )
        .subscribe(() => this.autoCallAPIWeather(false));
    });
  }
  /**
   * on destroy
   */
  ngOnDestroy() {
    this.pausePreviewSubject.next();
    this.clearAllOpenWeather();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.highlightAreaPreviewSubject.unsubscribe();
    this.pausePreviewSubject.unsubscribe();
    this.resetTimerPreviewSubject.unsubscribe();
    this.store.dispatch(
      new SaveExternalContentManagerStateAction({
        isChangeLayout: true,
        contents: this.contents,
        indexWordGroups: this.indexWordGroups,
        isActiveNewsTab: this.isActiveNewsTab,
        isActiveWeatherTab: this.isActiveWeatherTab,
        isEnlargePreview: this.isEnlargePreview,
        isChangeMunicipalCode: this.isChangeMunicipalCode,
        contentSelected: this.contentSelected,
        contentDetailSelected: this.contentDetailSelected,
        areas: this.areas,
        newsCurrentPage: this.newsCurrentPage,
        areaDisabledOptions: this.areaDisabledOptions,
        inputFocusCurrent: this.inputFocusCurrent,
        contentCloneSelected: this.contentCloneSelected,
        isChangedData: this.isChangedData,
        isChangedNewsTab: this.isChangedNewsTab,
        isChangedWeatherTab: this.isChangedWeatherTab,
        isChangedOpenWeatherTab: this.isChangedOpenWeatherTab,
        intervalCallApi: this.intervalCallApi,
        cityListFromImport: this.cityListFromImport,
        apiDetails: this.apiDetails,
        apiCycle: this.apiCycle,
        isZoom: this.isZoom,
        isPan: this.isPan
      })
    );
    delete this.commonObject.isActiveNewsTab;
    delete this.commonObject.isActiveWeatherTab;
  }

  /**
   * set data for component after change layout
   */
  private handleSetDataForComponentAfterChangeLayout() {
    this.contents = this.stateOfComponent.contents;
    this.indexWordGroups = this.stateOfComponent.indexWordGroups;
    this.isActiveNewsTab = this.stateOfComponent.isActiveNewsTab;
    this.isActiveWeatherTab = this.stateOfComponent.isActiveWeatherTab;
    this.isEnlargePreview = this.stateOfComponent.isEnlargePreview;
    this.isChangeMunicipalCode = this.stateOfComponent.isChangeMunicipalCode;
    this.contentSelected = this.stateOfComponent.contentSelected;
    this.contentDetailSelected = this.stateOfComponent.contentDetailSelected;
    this.areas = this.stateOfComponent.areas;
    this.newsCurrentPage = this.stateOfComponent.newsCurrentPage;
    this.areaDisabledOptions = this.stateOfComponent.areaDisabledOptions;
    this.inputFocusCurrent = this.stateOfComponent.inputFocusCurrent;
    this.contentCloneSelected = this.stateOfComponent.contentCloneSelected;
    this.isChangedData = this.stateOfComponent.isChangedData;
    this.isChangedNewsTab = this.stateOfComponent.isChangedNewsTab;
    this.isChangedWeatherTab = this.stateOfComponent.isChangedWeatherTab;
    this.isChangedOpenWeatherTab = this.stateOfComponent.isChangedOpenWeatherTab;
    this.intervalCallApi = this.stateOfComponent.intervalCallApi;
    this.dataService.sendData([Constant.IS_TAB_OPEN_WEATHER, !this.isActiveNewsTab && !this.isActiveWeatherTab]);
    this.dataService.sendData([Constant.USER_ROOT, this.commonObject.user.userId == Constant.USER_ROOT]);
    if (!this.contents || this.contents.length == 0) {
      this.dataService.sendData([Constant.HAS_CONTENT, false]);
    } else {
      this.dataService.sendData([Constant.HAS_CONTENT, true]);
    }
    this.isPlayPreview = false;
    this.newsTotalPages = this.isActiveNewsTab && this.contentSelected ? this.contentSelected.pageCounts : 1;
    this.changeDetectorRef.detectChanges();
    this.handleDrawContent();
    this.commonObject.isActiveNewsTab = this.isActiveNewsTab;
    this.commonObject.isActiveWeatherTab = this.isActiveWeatherTab;
    this.cityListFromImport = this.stateOfComponent.cityListFromImport;
    this.isReloadContentData = this.stateOfComponent.isReloadContentData;
    this.isPlayPreview = this.stateOfComponent.isPlayPreview;
    this.newsTotalPages = this.stateOfComponent.newsTotalPages;
    this.isCheckLoadOpenWeatherTab = this.stateOfComponent.isCheckLoadOpenWeatherTab;
    this.timeOutBeginStartCall = this.stateOfComponent.timeOutBeginStartCall;
    this.timeOutEndCall = this.stateOfComponent.timeOutEndCall;
    this.apiDetails = this.stateOfComponent.apiDetails;
    this.apiCycle = this.stateOfComponent.apiCycle;
    this.isZoom = this.stateOfComponent?.isZoom;
    this.isPan = this.stateOfComponent?.isPan;
    Helper.saveMainStateAction(this.store, this.commonObject);
  }

  /**
   * dispatch content tab data to store (news / weather)
   */
  private dispatchContentTabDataToStore(tabSave: string) {
    switch (tabSave) {
      case 'weatherTab':
        this.store.dispatch(
          new SaveWeatherContentTabStateAction({
            indexWordGroups: this.indexWordGroups,
            contents: this.contents,
            isChangeMunicipalCode: this.isChangeMunicipalCode,
            contentSelected: this.contentSelected,
            contentDetailSelected: this.contentDetailSelected,
            areas: this.areas,
            areaDisabledOptions: this.areaDisabledOptions,
            inputFocusCurrent: this.inputFocusCurrent,
            contentCloneSelected: this.contentCloneSelected
          })
        );
        break;
      case 'newsTab':
        this.store.dispatch(
          new SaveNewsContentTabStateAction({
            contents: this.contents,
            contentSelected: this.contentSelected,
            contentDetailSelected: this.contentDetailSelected,
            areas: this.areas,
            newsCurrentPage: this.newsCurrentPage,
            areaDisabledOptions: this.areaDisabledOptions,
            inputFocusCurrent: this.inputFocusCurrent,
            contentCloneSelected: this.contentCloneSelected
          })
        );
        break;
      case 'openWeatherTab':
        this.store.dispatch(
          new SaveOpenWeatherContentTabStateAction({
            indexWordGroups: this.indexWordGroups,
            contents: this.contents,
            contentSelected: this.contentSelected,
            contentDetailSelected: this.contentDetailSelected,
            areas: this.areas,
            areaDisabledOptions: this.areaDisabledOptions,
            inputFocusCurrent: this.inputFocusCurrent,
            contentCloneSelected: this.contentCloneSelected,
            cityListFromImport: this.cityListFromImport
          })
        );
        break;
      default:
      // code block
    }
  }

  /**
   * handle set data for news content tab from store
   */
  private handleSetDataForNewsTabAfterChangeTab() {
    this.contents = this.newsContentTabStateOfComponent.contents;
    this.contentSelected = this.newsContentTabStateOfComponent.contentSelected;
    this.contentDetailSelected = this.newsContentTabStateOfComponent.contentDetailSelected;
    this.areas = this.newsContentTabStateOfComponent.areas;
    this.newsCurrentPage = this.newsContentTabStateOfComponent.newsCurrentPage;
    this.areaDisabledOptions = this.newsContentTabStateOfComponent.areaDisabledOptions;
    this.inputFocusCurrent = this.newsContentTabStateOfComponent.inputFocusCurrent;
    this.contentCloneSelected = this.newsContentTabStateOfComponent.contentCloneSelected;
    this.isPlayPreview = false;
    this.newsTotalPages = this.contentSelected ? this.contentSelected.pageCounts : 1;
    this.handleDrawContent();
  }

  /**
   * handle set data for weather content tab from store
   */
  private handleSetDataForWeatherTabAfterChangeTab() {
    this.indexWordGroups = this.weatherContentTabStateOfComponent.indexWordGroups;
    this.contents = this.weatherContentTabStateOfComponent.contents;
    this.isChangeMunicipalCode = this.weatherContentTabStateOfComponent.isChangeMunicipalCode;
    this.contentSelected = this.weatherContentTabStateOfComponent.contentSelected;
    this.contentDetailSelected = this.weatherContentTabStateOfComponent.contentDetailSelected;
    this.areas = this.weatherContentTabStateOfComponent.areas;
    this.areaDisabledOptions = this.weatherContentTabStateOfComponent.areaDisabledOptions;
    this.inputFocusCurrent = this.weatherContentTabStateOfComponent.inputFocusCurrent;
    this.contentCloneSelected = this.weatherContentTabStateOfComponent.contentCloneSelected;
    this.newsTotalPages = 1;
    this.newsCurrentPage = 1;
    this.handleDrawContent();
  }

  /**
   * handle set data for weather content tab from store
   */
  private handleSetDataForOpenWeatherTabAfterChangeTab() {
    this.indexWordGroups = this.openWeatherContentTabStateOfComponent.indexWordGroups;
    this.contentDetailSelected = this.openWeatherContentTabStateOfComponent.contentDetailSelected;
    this.inputFocusCurrent = this.openWeatherContentTabStateOfComponent.inputFocusCurrent;
    this.contentCloneSelected = this.openWeatherContentTabStateOfComponent.contentCloneSelected;
    this.cityListFromImport = this.openWeatherContentTabStateOfComponent.cityListFromImport;
    this.newsTotalPages = 1;
    this.newsCurrentPage = 1;
    this.handleDrawContent();
  }

  /**
   * handle draw content after change tab
   */
  private handleDrawContent() {
    if (this.contentSelected?.template) {
      Helper.createCanvasTemplateExternalContentManager(this.contentSelected.template, this.divContainCanvas, this.renderer);
      this.createAllCanvasAreaTemplate(this.contentSelected.template);
      this.calculateScaleTransformCanvas(this.contentSelected.template);
      this.drawPreviewForContent(this.contentSelected, false);
      this.highlightAreaPreviewSubject.next();
    }
  }

  /**
   * choose news tab
   */
  public chooseNewsTab() {
    this.clearAllOpenWeather();
    if (this.divContainCanvas) {
      Helper.clearNodeChild(this.divContainCanvas.nativeElement);
    }
    this.dataService.sendData([Constant.IS_TAB_OPEN_WEATHER, false]);
    let saveTab = this.isActiveWeatherTab ? 'weatherTab' : 'openWeatherTab';
    this.isActiveNewsTab = true;
    this.isActiveWeatherTab = false;
    this.commonObject.isActiveNewsTab = this.isActiveNewsTab;
    this.commonObject.isActiveWeatherTab = this.isActiveWeatherTab;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.dispatchContentTabDataToStore(saveTab);
    this.contents = [];
    this.contentSelected = undefined;
    this.contentDetailSelected = undefined;
    this.newsCurrentPage = 1;
    if (!this.newsContentTabStateOfComponent.isChangeTab) {
      this.store.dispatch(
        new SaveNewsContentTabStateAction({
          isChangeTab: true
        })
      );
      // get data news contents
      this.newsContentService.getNewsContents().subscribe(
        newsContentsDataResponse => {
          this.contents = Helper.convertDataNewsContents(newsContentsDataResponse);
          this.selectContent(this.contents[0]);
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      this.handleSetDataForNewsTabAfterChangeTab();
    }
  }

  /**
   * startActionChooseTab
   * @param isWeatherTab
   */
  private startActionChooseTab(isWeatherTab: boolean) {
    this.isPan = false;
    this.isZoom = false;
    setTimeout(() => {
      if (!this.isChangedOpenWeatherTab && !this.contentSelected?.isEdit && !this.isCheckLoadOpenWeatherTab) {
        if (isWeatherTab) {
          this.chooseWeatherTab();
        } else {
          this.chooseNewsTab();
        }
      } else if (!this.isCheckLoadOpenWeatherTab) {
        this.startActionChooseTab(isWeatherTab);
      } else if (this.isCheckLoadOpenWeatherTab) {
        let el: HTMLElement = this.myDiv.nativeElement;
        el.click();
        el.focus();
      }
    }, 50);
  }

  /**
   * choose weather tab
   */
  public chooseWeatherTab() {
    this.clearAllOpenWeather();
    if (this.divContainCanvas) {
      Helper.clearNodeChild(this.divContainCanvas.nativeElement);
    }
    this.dataService.sendData([Constant.IS_TAB_OPEN_WEATHER, false]);
    let saveTab = this.isActiveNewsTab ? 'newsTab' : 'openWeatherTab';
    this.isActiveNewsTab = false;
    this.isActiveWeatherTab = true;
    this.commonObject.isActiveNewsTab = this.isActiveNewsTab;
    this.commonObject.isActiveWeatherTab = this.isActiveWeatherTab;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.dispatchContentTabDataToStore(saveTab);
    this.contents = [];
    this.contentSelected = undefined;
    this.contentDetailSelected = undefined;
    this.pausePreviewSubject.next();
    if (!this.weatherContentTabStateOfComponent.isChangeTab) {
      this.store.dispatch(
        new SaveWeatherContentTabStateAction({
          isChangeTab: true
        })
      );
      // get data index word groups
      this.indexWordGroupService.getIndexWordGroups().subscribe(
        iwGroupsDataResponse => {
          this.indexWordGroups = iwGroupsDataResponse;
          // get data weather contents
          this.weatherContentService.getWeatherContents().subscribe(
            weatherContentsDataResponse => {
              this.contents = Helper.convertDataWeatherContents(weatherContentsDataResponse);
              this.selectContent(this.contents[0]);
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      this.handleSetDataForWeatherTabAfterChangeTab();
    }
  }

  /**
   * choose open weather tab
   */
  public chooseOpenWeatherTab() {
    this.isPan = false;
    this.isZoom = false;
    if (this.isCheckLoadOpenWeatherTab) {
      this.isCheckLoadOpenWeatherTab = false;
      return;
    }
    this.dataService.sendData([Constant.USER_ROOT, this.commonObject.user.userId == Constant.USER_ROOT]);
    if (this.divContainCanvas) {
      Helper.clearNodeChild(this.divContainCanvas.nativeElement);
    }
    this.dataService.sendData([Constant.IS_TAB_OPEN_WEATHER, true]);
    let saveTab = this.isActiveNewsTab ? 'newsTab' : 'weatherTab';
    this.isActiveNewsTab = false;
    this.isActiveWeatherTab = false;
    this.commonObject.isActiveNewsTab = this.isActiveNewsTab;
    this.commonObject.isActiveWeatherTab = this.isActiveWeatherTab;
    Helper.saveMainStateAction(this.store, this.commonObject);
    this.dispatchContentTabDataToStore(saveTab);
    this.contents = [];
    this.contentSelected = undefined;
    this.contentDetailSelected = undefined;
    this.pausePreviewSubject.next();
    if (!this.openWeatherContentTabStateOfComponent.isChangeTab) {
      this.store.dispatch(
        new SaveOpenWeatherContentTabStateAction({
          isChangeTab: true
        })
      );
      if (this.apiCycle && this.apiCycle.startTime && this.apiDetails && this.apiDetails.apiKey) {
        this.timeOutBeginStartCall = setTimeout(() => {
          this.startAutoCallApiSubject.next();
          this.autoCallAPIWeather(false);
        }, this.calculatorTimeStartCall());
        this.timeOutEndCall = setTimeout(() => {
          this.clearAllOpenWeather();
        }, this.calculatorEndCall());
      }
      if (!this.cityListFromImport) {
        this.openWeatherContentService.getAllCity().subscribe(
          cityList => {
            this.cityListFromImport = cityList;
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
      }
      if (!this.indexWordGroups) {
        // get data index word groups
        this.indexWordGroupService.getIndexWordGroups().subscribe(
          iwGroupsDataResponse => {
            this.indexWordGroups = iwGroupsDataResponse;
            // get data weather contents
            this.openWeatherContentService.autoCallAPIWeather(this.apiDetails ? this.apiDetails : new APIDetails(), true, -1).subscribe(
              openWeatherContentsDataResponse => {
                this.contents = Helper.convertDataOpenWeatherContents(openWeatherContentsDataResponse);
                if (!this.contents || this.contents.length == 0) {
                  this.dataService.sendData([Constant.HAS_CONTENT, false]);
                } else {
                  this.dataService.sendData([Constant.HAS_CONTENT, true]);
                }
                if (this.contents.every(content => !content.isAutoUpdate)) {
                  this.clearAllOpenWeather();
                }
                this.selectContent(this.contents[0]);
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
      } else {
        this.openWeatherContentService.autoCallAPIWeather(this.apiDetails ? this.apiDetails : new APIDetails(), true, -1).subscribe(
          openWeatherContentsDataResponse => {
            this.contents = Helper.convertDataOpenWeatherContents(openWeatherContentsDataResponse);
            if (!this.contents || this.contents.length == 0) {
              this.dataService.sendData([Constant.HAS_CONTENT, false]);
            } else {
              this.dataService.sendData([Constant.HAS_CONTENT, true]);
            }
            if (this.contents.every(content => !content.isAutoUpdate)) {
              this.clearAllOpenWeather();
            }
            this.selectContent(this.contents[0]);
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
      }
    } else {
      this.openWeatherContentService.autoCallAPIWeather(this.apiDetails ? this.apiDetails : new APIDetails(), true, -1).subscribe(
        openWeatherContentsDataResponse => {
          this.contents = Helper.convertDataOpenWeatherContents(openWeatherContentsDataResponse);
          if (!this.contents || this.contents.length == 0) {
            this.dataService.sendData([Constant.HAS_CONTENT, false]);
          } else {
            this.dataService.sendData([Constant.HAS_CONTENT, true]);
          }
          if (this.contents.every(content => !content.isAutoUpdate)) {
            this.clearAllOpenWeather();
          }
          let indexSelectContent = this.contents.findIndex(e => e.id == this.openWeatherContentTabStateOfComponent.contentSelected.id);
          this.selectContent(this.contents[indexSelectContent]);
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
      this.handleSetDataForOpenWeatherTabAfterChangeTab();
    }
    // const account = this.commonObject.tenantName.toUpperCase();
    // this.weatherContentDetailService.callApiAWS('get', account).subscribe(
    //   data => {
    //     console.log(data);
    //   },
    //   error => {
    //     console.log(error);
    //   }
    // );
  }
  /**
   * calculatorTimeStartCall
   * @returns
   */
  private calculatorTimeStartCall(): number {
    const hourStart = this.apiCycle.startTime.split(':')[0];
    const minuteStart = this.apiCycle.startTime.split(':')[1];
    const currentTime = new Date().getTime();
    const timeStartCall = new Date().setHours(hourStart, minuteStart);
    if (timeStartCall - currentTime > 0) {
      return timeStartCall - currentTime;
    } else {
      return this.apiCycle.interval * 3600000;
    }
  }

  /**
   * calculatorEndCall
   * @returns
   */
  private calculatorEndCall(): number {
    const hourEnd = this.apiCycle.endTime.split(':')[0];
    const minuteEnd = this.apiCycle.endTime.split(':')[1];
    const currentTime = new Date().getTime();
    const timeEndCall = new Date().setHours(hourEnd, minuteEnd);
    if (timeEndCall - currentTime > 0) {
      return timeEndCall - currentTime;
    } else {
      return 0;
    }
  }

  /**
   * clearAllOpenWeather
   */
  private clearAllOpenWeather(): void {
    this.pauseAutoCallApiSubject.next();
    this.intervalCallApi?.unsubscribe();
    if (this.timeOutBeginStartCall) {
      clearTimeout(this.timeOutBeginStartCall);
    }
    if (this.timeOutEndCall) {
      clearTimeout(this.timeOutEndCall);
    }
  }
  /**
   * select content
   *
   * @param content
   * @param isChangeTemplate
   */
  public selectContent(content: any, isChangeTemplate?: boolean) {
    if (content.interval === null) {
      content.interval = 0;
    }
    if (!content || this.contentSelected?.isEdit || (this.contentSelected === content && !isChangeTemplate)) {
      return;
    }
    if (this.isPlayPreview) {
      this.pausePreviewSubject.next();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING)
        },
        autoFocus: false
      });
      return;
    }
    this.pausePreviewSubject.next();
    this.contentDetailSelected = undefined;
    this.changeDetectorRef.detectChanges();
    Helper.clearNodeChild(this.divContainCanvas.nativeElement);
    this.contentSelected = content;
    if (this.isPan) {
      this.chooseTool(PreviewToolEnum.PAN);
    }
    if (this.isZoom) {
      this.chooseTool(PreviewToolEnum.ZOOM);
    }
    this.newsCurrentPage = 1;
    this.newsTotalPages = this.isActiveNewsTab ? this.contentSelected.pageCounts : 1;
    if (!this.contentSelected.template) {
      return;
    }
    if (this.isActiveNewsTab) {
      this.newsContentService.getNewsContentWithFullDataTemplateById(content.id).subscribe(
        newsContentDataResponse => {
          this.setDataForContent(newsContentDataResponse, isChangeTemplate);
          this.newsContentDetailService.getNewsContentDetailsByContentId(this.contentSelected.id).subscribe(
            newsContentDetailsDataResponse => {
              this.contentSelected.newsContentDetails = newsContentDetailsDataResponse.map(
                Helper.convertDataNewsContentDetailFromServer.bind(Helper)
              );
              this.drawPreviewForContent(this.contentSelected, false);
              this.areaDisabledOptions = this.contentSelected.newsContentDetails.map(newsContentDetail => newsContentDetail.area);
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else if (this.isActiveWeatherTab) {
      this.weatherContentService.getWeatherContentWithFullDataTemplateById(this.contentSelected.id).subscribe(
        weatherContentDataResponse => {
          this.setDataForContent(weatherContentDataResponse, isChangeTemplate);
          this.weatherContentDetailService.getWeatherContentDetailsByContentId(this.contentSelected.id).subscribe(
            weatherContentDetailsDataResponse => {
              this.contentSelected.weatherContentDetails = weatherContentDetailsDataResponse.map(
                Helper.convertDataWeatherContentDetailFromServer.bind(Helper)
              );
              this.drawPreviewForContent(this.contentSelected, false);
              this.areaDisabledOptions = this.contentSelected.weatherContentDetails.map(weatherContentDetail => weatherContentDetail.area);
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      this.isChangedOpenWeatherTab = false;
      this.weatherContentService.getOpenWeatherContentWithFullDataTemplateById(this.contentSelected.id).subscribe(
        weatherContentDataResponse => {
          if (!weatherContentDataResponse) {
            return;
          }
          this.isDayMode = weatherContentDataResponse.interval == 1;
          this.setDataForContent(weatherContentDataResponse, isChangeTemplate);
          this.weatherContentDetailService.getOpenWeatherContentDetailsByContentId(this.contentSelected.id, this.apiDetails).subscribe(
            weatherContentDetailsDataResponse => {
              this.contentSelected.weatherContentDetails = weatherContentDetailsDataResponse.map(
                Helper.convertDataOpenWeatherContentDetailFromServer.bind(Helper)
              );
              this.drawPreviewForContent(this.contentSelected, false);
              this.areaDisabledOptions = this.contentSelected.weatherContentDetails.map(weatherContentDetail => weatherContentDetail.area);
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    }
  }

  /**
   * select content detail
   *
   * @param contentDetail
   * @returns
   */
  public selectContentDetail(contentDetail: any) {
    if (this.contentDetailSelected == contentDetail) {
      return;
    }
    this.contentDetailSelected = contentDetail;
    this.highlightAreaPreviewSubject.next();
  }

  /**
   * set data for content
   *
   * @param contentDataResponse
   * @param isChangeTemplate
   */
  private setDataForContent(contentDataResponse: any, isChangeTemplate: boolean) {
    const index = this.contents.findIndex(content => content.id == this.contentSelected.id);
    if (index == -1) {
      return;
    }
    if (this.isActiveNewsTab) {
      this.contents[index] = Helper.convertDataNewsContentFromServer(contentDataResponse);
    } else if (this.isActiveWeatherTab) {
      this.contents[index] = Helper.convertDataWeatherContentFromServer(contentDataResponse);
    } else {
      this.contents[index] = Helper.convertDataOpenWeatherContentFromServer(contentDataResponse);
    }
    this.contentSelected = this.contents[index];
    if (this.contents[index].template == null) {
      return;
    }
    this.areas = this.isActiveNewsTab
      ? Helper.getAllAreaTemplate(this.contents[index].template).filter(area => !area.isFix && area.checkTypeTextArea())
      : Helper.getAllAreaTemplate(this.contents[index].template).filter(area => !area.isFix);
    Helper.createCanvasTemplateExternalContentManager(this.contents[index].template, this.divContainCanvas, this.renderer);
    this.createAllCanvasAreaTemplate(this.contents[index].template);
    this.calculateScaleTransformCanvas(this.contents[index].template);
    if (isChangeTemplate && this.areas.length == 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title:
            this.isActiveNewsTab || this.isActiveWeatherTab ? 'Warning' : this.translateService.instant('external-content-manager.warning'),
          text:
            this.isActiveNewsTab || this.isActiveWeatherTab
              ? 'Template of the selected content does not contain link area.'
              : this.translateService.instant('external-content-manager.template-not-contain')
        },
        autoFocus: false
      });
    }
  }

  /**
   * add content
   */
  private addContent() {
    let error = undefined;
    if (this.contentSelected?.isEdit || this.isChangedOpenWeatherTab) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.editing-mode');
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title:
            this.isActiveNewsTab || this.isActiveWeatherTab ? 'Error' : this.translateService.instant('external-content-manager.error'),
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.contentDetailSelected = undefined;
    Helper.clearNodeChild(this.divContainCanvas.nativeElement);
    if (this.isActiveNewsTab) {
      this.pausePreviewSubject.next();
      this.newsCurrentPage = 1;
      const news = new NewsContent('', 5, 5);
      this.contents.push(news);
    } else if (this.isActiveWeatherTab) {
      const weather = new WeatherContent('', '');
      this.contents.push(weather);
    } else {
      const openWeather = new OpenWeatherContent('');
      this.contents.push(openWeather);
    }
    this.contentSelected = this.contents[this.contents.length - 1];
    this.contentSelected.isEdit = true;
    this.isChangedData = true;
    if (this.contents.length > 9) {
      this.changeDetectorRef.detectChanges();
      this.scrollIntoViewSelected(this.selectElement, this.contents.length - 1);
    }
  }

  /**
   * edit content
   */
  private editContent() {
    let error = undefined;
    if (!this.contentSelected) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.please-choose-content-selected');
    } else if (this.contentSelected.isEdit) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.editing-mode');
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title:
            this.isActiveNewsTab || this.isActiveWeatherTab ? 'Error' : this.translateService.instant('external-content-manager.error'),
          text: error
        },
        autoFocus: false
      });
      return;
    }

    this.contentDetailSelected = undefined;
    this.highlightAreaPreviewSubject.next();
    this.contentCloneSelected = _.cloneDeep(this.contentSelected);
    this.contentSelected.isEdit = true;
    this.isChangedData = true;
  }

  /**
   * save edit content
   *
   * @param isSaveAllContentDetail
   */
  public saveEditContent(isSaveAllContentDetail: boolean) {
    let error = this.validateContent();
    if (error) {
      if (error.constructor === Array) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              texts: error
            },
            autoFocus: false
          },
          () => {
            this.inputFocusCurrent.focus();
          }
        );
      } else {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.isActiveNewsTab || this.isActiveWeatherTab ? 'Error' : this.translateService.instant('dialog-error.title'),
              text: error
            },
            autoFocus: false
          },
          () => {
            this.inputFocusCurrent.focus();
          }
        );
      }
      this.isCheckLoadOpenWeatherTab = true;
      return;
    }
    this.contentSelected.contentOutputFileName = this.contentSelected.contentOutputFileName.trim();
    if (this.isActiveNewsTab) {
      if (!this.contentSelected.id) {
        this.newsContentService.addNewsContent(this.contentSelected).subscribe(
          newsContentDataResponse => {
            if (newsContentDataResponse.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: 'Error',
                  text: Helper.getErrorMessage(ErrorEnum.MAX_LENGTH, this.propertiesName.content, this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME)
                }
              });
              return;
            }
            this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataNewsContentFromServer(newsContentDataResponse));
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
      } else {
        this.newsContentService.editNewsContent(Helper.convertDataNewsContentFromClient(this.contentSelected)).subscribe(
          newsContentDataResponse => {
            if (newsContentDataResponse.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
              this.dialogService.showDialog(DialogMessageComponent, {
                data: {
                  title: 'Error',
                  text: Helper.getErrorMessage(ErrorEnum.MAX_LENGTH, this.propertiesName.content, this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME)
                }
              });
              return;
            }
            this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataNewsContentFromServer(newsContentDataResponse));
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
      }
    } else if (this.isActiveWeatherTab) {
      this.contentSelected.municipalCode = this.contentSelected.municipalCode.trim();
      if (this.isChangeMunicipalCode) {
        const getMunicipalName = () => {
          return new Promise<void>(resolve => {
            this.municipalService.getMunicipalByCode(this.contentSelected.municipalCode).subscribe(data => {
              this.contentSelected.municipalName = data ? `${data.cityName}${data.municipalName}` : null;
              this.isChangeMunicipalCode = false;
              resolve();
            });
          });
        };
        getMunicipalName().then(() => {
          if (!this.contentSelected.id) {
            this.weatherContentService.addWeatherContent(this.contentSelected).subscribe(
              weatherContentDataResponse => {
                if (weatherContentDataResponse.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: 'Error',
                      text: Helper.getErrorMessage(
                        ErrorEnum.MAX_LENGTH,
                        this.propertiesName.content,
                        this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME
                      )
                    }
                  });
                  return;
                }
                this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataWeatherContentFromServer(weatherContentDataResponse));
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          } else {
            this.weatherContentService.editWeatherContent(Helper.convertDataWeatherContentFromClient(this.contentSelected)).subscribe(
              weatherContentDataResponse => {
                if (weatherContentDataResponse.id == Constant.ERROR_CODE_MAX_LENGTH_NAME) {
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: 'Error',
                      text: Helper.getErrorMessage(
                        ErrorEnum.MAX_LENGTH,
                        this.propertiesName.content,
                        this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME
                      )
                    }
                  });
                  return;
                }
                this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataWeatherContentFromServer(weatherContentDataResponse));
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          }
        });
      } else {
        if (!this.contentSelected.id) {
          this.weatherContentService.addWeatherContent(this.contentSelected).subscribe(
            weatherContentDataResponse => {
              this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataWeatherContentFromServer(weatherContentDataResponse));
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        } else {
          this.weatherContentService.editWeatherContent(Helper.convertDataWeatherContentFromClient(this.contentSelected)).subscribe(
            weatherContentDataResponse => {
              this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataWeatherContentFromServer(weatherContentDataResponse));
            },
            error => {
              this.handleErrorFromApi(error);
            }
          );
        }
      }
    } else {
      this.openWeatherContentService.saveOpenWeatherContent(Helper.convertDataOpenBackward(this.contentSelected)).subscribe(
        weatherContentDataResponse => {
          this.handleAfterEditContent(isSaveAllContentDetail, Helper.convertDataOpenWeatherContentFromServer(weatherContentDataResponse));
          this.weatherContentDetailService
            .callApiAwsEntry('delete', this.accountId, this.contentCloneSelected.contentOutputFileName)
            .subscribe(
              data => {
                //console.log(data);
                this.weatherContentDetailService
                  .callApiAwsEntry('put', this.accountId, this.contentSelected.contentOutputFileName, this.contentSelected.isAutoUpdate)
                  .subscribe(
                    data => {
                      //console.log(data);
                    },
                    error => {
                      console.log(error);
                    }
                  );
              },
              error => {
                console.log(error);
              }
            );

          if (!this.contents || this.contents.length == 0) {
            this.dataService.sendData([Constant.HAS_CONTENT, false]);
          } else {
            this.dataService.sendData([Constant.HAS_CONTENT, true]);
          }
          this.isCheckLoadOpenWeatherTab = false;
          this.weatherContentDetailService
            .uploadAfterSave(
              this.contentSelected.id,
              this.contentSelected.contentOutputFileName,
              this.contentCloneSelected.contentOutputFileName
            )
            .subscribe(
              data => {
                console.log(data);
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
        },
        error => {
          this.handleErrorFromApi(error);
          this.isCheckLoadOpenWeatherTab = true;
        }
      );
    }
  }

  /**
   * handle after add content
   *
   * @param contentDataResponse
   * @param isSaveAllContentDetail
   */
  private handleAfterEditContent(isSaveAllContentDetail: boolean, contentDataResponse?: any) {
    const index = this.contents.findIndex(content => content.id == this.contentSelected.id);
    if (index == -1) {
      return;
    }
    this.setContentDataAfterUpdate(this.contents[index], contentDataResponse);
    this.contentSelected = this.contents[index];
    this.newsTotalPages = this.isActiveNewsTab ? this.contentSelected.pageCounts : 1;
    this.contentSelected.isEdit = false;
    this.isChangedData =
      this.newsContentTabStateOfComponent?.contentSelected?.isEdit ||
      this.weatherContentTabStateOfComponent?.contentSelected?.isEdit ||
      this.openWeatherContentTabStateOfComponent?.contentSelected?.isEdit;
    if (isSaveAllContentDetail) {
      this.saveBeforeLeave();
    } else {
      this.saveDataSuccess.emit(true);
    }
  }

  /**
   * Set content data after update
   *
   * @param content
   * @param contentDataResponse
   */
  private setContentDataAfterUpdate(content: any, contentDataResponse: any): void {
    content.id = contentDataResponse.id;
    content.contentOutputFileName = contentDataResponse.contentOutputFileName;
    content.contentOutputFileNameEncode = contentDataResponse.contentOutputFileNameEncode;
    content.isEdit = contentDataResponse.isEdit;
    if (this.isActiveNewsTab) {
      content['duration'] = contentDataResponse['duration'];
      content['pageCounts'] = contentDataResponse['pageCounts'];
    } else if (this.isActiveWeatherTab) {
      content['municipalCode'] = contentDataResponse['municipalCode'];
      content['municipalName'] = contentDataResponse['municipalName'];
    } else {
      content['cities'] = contentDataResponse['cities'];
      content['autoUpdate'] = contentDataResponse['autoUpdate'];
    }
  }

  /**
   * validate content output file name
   *
   * @returns error
   */
  private validateContentOutputFileName() {
    const contentNames = this.contents?.filter(content => content.id != this.contentSelected.id).map(e => e.contentOutputFileName);
    if (this.contentSelected.contentOutputFileName.trim() == '') {
      this.inputFocusCurrent = this.contentOutputInput.nativeElement;
      if (this.isActiveNewsTab || this.isActiveWeatherTab) {
        return Helper.getErrorMessage(ErrorEnum.EMPTY, this.propertiesName.contentOutputFileName);
      }
      return this.translateService.instant('external-content-manager.msg.content-name-empty');
    } else if (this.contentSelected.contentOutputFileName.length > this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME) {
      this.inputFocusCurrent = this.contentOutputInput.nativeElement;
      return Helper.getErrorMessage(
        ErrorEnum.MAX_LENGTH,
        this.propertiesName.contentOutputFileName,
        this.MAX_LENGTH_CONTENT_OUTPUT_FILE_NAME
      );
    } else if (!this.isActiveNewsTab && !this.isActiveWeatherTab && contentNames?.includes(this.contentSelected.contentOutputFileName)) {
      this.inputFocusCurrent = this.contentOutputInput.nativeElement;
      return [
        this.translateService.instant('external-content-manager.msg.duplicate-content-name1'),
        this.translateService.instant('external-content-manager.msg.duplicate-content-name2')
      ];
    }
    return undefined;
  }

  /**
   * validate municipal code
   *
   * @returns error
   */
  private validateMunicipalCode() {
    if (this.contentSelected.municipalCode.trim() == '') {
      this.inputFocusCurrent = this.municipalCodeInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.EMPTY, this.propertiesName.municipalCode);
    } else if (this.contentSelected.municipalCode.length > this.MAX_LENGTH_MUNICIPAL_CODE) {
      this.inputFocusCurrent = this.municipalCodeInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.MAX_LENGTH, this.propertiesName.municipalCode, this.MAX_LENGTH_MUNICIPAL_CODE);
    }
    return undefined;
  }

  /**
   * validate page counts
   *
   * @returns error
   */
  private validatePageCounts() {
    if (this.contentSelected.pageCounts == null) {
      this.inputFocusCurrent = this.pageCountsInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.EMPTY, this.propertiesName.pageCounts);
    } else if (this.contentSelected.pageCounts < this.MIN_PAGE_COUNTS) {
      this.inputFocusCurrent = this.pageCountsInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.MIN, this.propertiesName.pageCounts, this.MIN_PAGE_COUNTS);
    } else if (this.contentSelected.pageCounts > this.MAX_PAGE_COUNTS) {
      this.inputFocusCurrent = this.pageCountsInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.MAX, this.propertiesName.pageCounts, this.MAX_PAGE_COUNTS);
    }
    return undefined;
  }

  /**
   * validate duration
   *
   * @returns error
   */
  private validateDuration() {
    if (this.contentSelected.pageCounts != 1 && this.contentSelected.duration == null) {
      this.inputFocusCurrent = this.durationInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.EMPTY, this.propertiesName.duration);
    } else if (this.contentSelected.duration != null && this.contentSelected.duration < this.MIN_DURATION) {
      this.inputFocusCurrent = this.durationInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.MIN, this.propertiesName.duration, this.MIN_DURATION);
    } else if (this.contentSelected.duration != null && this.contentSelected.duration > this.MAX_DURATION) {
      this.inputFocusCurrent = this.durationInput.nativeElement;
      return Helper.getErrorMessage(ErrorEnum.MAX, this.propertiesName.duration, this.MAX_DURATION);
    }
    return undefined;
  }

  /**
   * validate content
   */
  private validateContent() {
    let error = this.validateContentOutputFileName();
    if (error) {
      return error;
    }
    if (this.isActiveNewsTab) {
      return this.validatePageCounts() || this.validateDuration();
    } else if (this.isActiveWeatherTab) {
      return this.validateMunicipalCode();
    }
  }

  /**
   * cancel edit content
   */
  public cancelEditContent() {
    this.contentSelected.isEdit = false;
    this.isCheckLoadOpenWeatherTab = false;
    this.isChangedData =
      this.newsContentTabStateOfComponent?.contentSelected?.isEdit || this.weatherContentTabStateOfComponent?.contentSelected?.isEdit;
    if (!this.contentSelected.id) {
      this.contents.pop();
      this.contentSelected = undefined;
      if (this.contents.length > 0) {
        this.selectContent(this.contents[0]);
        this.scrollIntoViewSelected(this.selectElement, 0);
      }
    } else {
      this.contentSelected.contentOutputFileName = this.contentCloneSelected['contentOutputFileName'];
      if (this.isActiveNewsTab) {
        this.contentSelected.pageCounts = this.contentCloneSelected['pageCounts'];
        this.contentSelected.duration = this.contentCloneSelected['duration'];
      } else {
        this.contentSelected.municipalCode = this.contentCloneSelected['municipalCode'];
        this.contentSelected.municipalName = this.contentCloneSelected['municipalName'];
      }
    }
    this.contentCloneSelected = undefined;
  }

  /**
   * delete content
   */
  private deleteContent() {
    let error = undefined;
    if (!this.contentSelected) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.please-choose-content-selected');
    } else if (this.contentSelected.isEdit) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.editing-mode');
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.isActiveNewsTab || this.isActiveWeatherTab ? 'Error' : this.translateService.instant('dialog-error.title'),
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text:
            this.isActiveNewsTab || this.isActiveWeatherTab
              ? `Do you want to delete ${this.contentSelected.contentOutputFileName}?`
              : Helper.formatString(
                  this.translateService.instant('external-content-manager.delete-content-selected'),
                  this.contentSelected.contentOutputFileName
                ),
          button1: this.isActiveNewsTab || this.isActiveWeatherTab ? 'Yes' : this.translateService.instant('external-content-manager.yes'),
          button2: this.isActiveNewsTab || this.isActiveWeatherTab ? 'No' : this.translateService.instant('external-content-manager.no'),
          title: this.isActiveNewsTab || this.isActiveWeatherTab ? 'Confirmation' : this.translateService.instant('dialog-confirm.title')
        },
        autoFocus: false
      },
      result => {
        if (result) {
          if (this.isActiveNewsTab) {
            this.newsContentService.deleteNewsContent(this.contentSelected.id).subscribe(
              () => {
                this.handleAfterDeleteContent();
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          } else if (this.isActiveWeatherTab) {
            this.weatherContentService.deleteWeatherContent(this.contentSelected.id).subscribe(
              () => {
                this.handleAfterDeleteContent();
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          } else {
            const contentOutputFileName = this.contentSelected.contentOutputFileName;
            this.weatherContentService.deleteOpenWeatherContent(this.contentSelected.id, contentOutputFileName).subscribe(
              () => {
                this.handleAfterDeleteContent();
                this.weatherContentDetailService.callApiAwsEntry('delete', this.accountId, contentOutputFileName).subscribe(
                  data => {
                    console.log(data);
                  },
                  error => {
                    console.log(error);
                  }
                );
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          }
        }
      }
    );
  }

  /**
   * delete content detail
   */
  private deleteContentDetail() {
    if (!this.contentDetailSelected) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: `Do you want to delete selected detailed content?`,
          button1: 'Yes',
          button2: 'No',
          title: 'Confirmation'
        },
        autoFocus: false
      },
      result => {
        if (result) {
          this.contentSelected.weatherContentDetails = this.contentSelected.weatherContentDetails.filter(
            weatherContentDetail => weatherContentDetail.uuid != this.contentDetailSelected.uuid
          );
          const areaDelete = Helper.getAllAreaTemplate(this.contentSelected.template).find(
            area => area.id == this.contentDetailSelected.area.id
          );
          if (areaDelete) {
            let ctx = areaDelete.canvas.getContext('2d');
            ctx.clearRect(0, 0, areaDelete.canvas.width, areaDelete.canvas.height);
            if (areaDelete.checkTypeTextArea()) {
              ctx.fillStyle = areaDelete.getArea().backgroundColor;
              ctx.fillRect(0, 0, areaDelete.canvas.width, areaDelete.canvas.height);
            }
          }
          this.contentDetailSelected =
            this.contentSelected.weatherContentDetails.length > 0 ? this.contentSelected.weatherContentDetails[0] : undefined;
          this.highlightAreaPreviewSubject.next();
          this.areaDisabledOptions = this.isActiveNewsTab
            ? this.contentSelected.newsContentDetails.map(newsContentDetail => newsContentDetail.area)
            : this.contentSelected.weatherContentDetails.map(weatherContentDetail => weatherContentDetail.area);
          this.isChangedWeatherTab = true;
          if (this.contentSelected.weatherContentDetails.length > 0) {
            this.scrollIntoViewSelected(this.selectDetailedElement, 0);
          }
        }
      }
    );
  }

  /**
   * handle after delete content
   */
  private handleAfterDeleteContent() {
    this.contents = this.contents.filter(content => content.id != this.contentSelected.id);
    this.contentSelected = undefined;
    if (this.divContainCanvas) {
      Helper.clearNodeChild(this.divContainCanvas);
    }
    this.selectContent(this.contents[0]);
    this.scrollIntoViewSelected(this.selectElement, 0);
  }

  /**
   * add weather content detail
   */
  public addContentDetail() {
    if (!this.contentSelected) {
      return;
    }
    if (this.areas.length == 0 || !this.contentSelected.template) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title:
            this.isActiveNewsTab || this.isActiveWeatherTab ? 'Error' : this.translateService.instant('external-content-manager.error'),
          text:
            this.isActiveNewsTab || this.isActiveWeatherTab
              ? 'Template of the selected content does not contain link area.'
              : this.translateService.instant('external-content-manager.template-not-contain')
        },
        autoFocus: false
      });
      return;
    }
    if (this.contentSelected.weatherContentDetails.length >= this.areas.length) {
      return;
    }
    if (this.isActiveWeatherTab) {
      this.isChangedWeatherTab = true;
      this.contentSelected.weatherContentDetails.push(new WeatherContentDetail(null, null, this.contentSelected.id, null, null));
    } else {
      this.isChangedOpenWeatherTab = true;
      this.contentSelected.weatherContentDetails.push(new OpenWeatherContentDetail(null, null, this.contentSelected.id, null, null, null));
    }
    if (this.contentSelected.weatherContentDetails.length > 16) {
      this.changeDetectorRef.detectChanges();
      this.scrollIntoViewSelected(this.selectDetailedElement, this.contentSelected.weatherContentDetails.length - 1);
    }
  }

  /**
   * change template
   */
  private changeTemplate() {
    let error = undefined;
    if (!this.contentSelected) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.please-choose-content-selected');
    } else if (this.contentSelected?.isEdit || this.isChangedOpenWeatherTab) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.editing-mode');
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.isActiveNewsTab || this.isActiveWeatherTab ? 'Error' : this.translateService.instant('dialog-error.title'),
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.isChangedData = true;
    this.dialogService.showDialog(
      DialogChangeTemplateForExternalContentComponent,
      {
        data: {
          template: this.contentSelected.template
        },
        autoFocus: false
      },
      result => {
        this.isChangedData = false;
        if (result) {
          if (this.contentSelected.template?.id == result.id) {
            return;
          }
          this.contentSelected.template = result.id ? result : null;
          if (this.isActiveNewsTab) {
            this.newsContentService.changeTemplateForNewsContent(Helper.convertDataNewsContentFromClient(this.contentSelected)).subscribe(
              () => {
                this.contentSelected.newsContentDetails = [];
                this.selectContent(this.contentSelected, true);
              },
              error => {
                this.handleErrorFromApi(error);
              }
            );
          } else if (this.isActiveWeatherTab) {
            this.weatherContentService
              .changeTemplateForWeatherContent(Helper.convertDataWeatherContentFromClient(this.contentSelected))
              .subscribe(
                () => {
                  this.contentSelected.weatherContentDetails = [];
                  this.selectContent(this.contentSelected, true);
                },
                error => {
                  this.handleErrorFromApi(error);
                }
              );
          } else {
            this.contentSelected.cities = [];
            this.weatherContentService
              .changeTemplateForOpenWeatherContent(Helper.convertDataOpenWeatherContentFromClient(this.contentSelected))
              .subscribe(
                () => {
                  this.contentSelected.weatherContentDetails = [];
                  this.selectContent(this.contentSelected, true);
                },
                error => {
                  this.handleErrorFromApi(error);
                }
              );
          }
        }
      }
    );
  }

  /**
   * change select area
   *
   * @param contentDetail
   * @param areaId
   */
  public changeSelectArea(contentDetail: any, areaId: any) {
    contentDetail.area = areaId ? this.areas.find(area => area.id == areaId) : null;
    contentDetail.source = null;
    this.dataService.sendData(['clearSelectBox', `selectBox${contentDetail.uuid}`]);
    if (this.isActiveNewsTab) {
      this.newsCurrentPage = 1;
      this.isChangedNewsTab = true;
    } else {
      contentDetail.forecastParam = null;
      contentDetail.indexWordGroup = null;
      this.isChangedOpenWeatherTab = true;
    }
    this.highlightAreaPreviewSubject.next();
    this.areaDisabledOptions = this.isActiveNewsTab
      ? this.contentSelected.newsContentDetails.map(newsContentDetail => newsContentDetail.area)
      : this.contentSelected.weatherContentDetails.map(weatherContentDetail => weatherContentDetail.area);
    this.drawPreviewForContent(this.contentSelected, true);
  }

  /**
   * change select source
   *
   * @param contentDetail
   * @param sourceValue
   */
  public changeSelectSource(contentDetail: any, sourceValue: any) {
    contentDetail.source = sourceValue ?? null;
    if (this.isActiveNewsTab) {
      this.newsCurrentPage = 1;
      this.isChangedNewsTab = true;
      contentDetail.texts = null;
      this.drawPreviewForContent(this.contentSelected, true);
    } else if (this.isActiveWeatherTab) {
      this.isChangedWeatherTab = true;
      if (contentDetail.source == WeatherContentSourceEnum.WEATHER_LOCATION) {
        contentDetail.forecastParam = null;
      }
      this.drawPreviewWhenWeatherDataChanged(contentDetail);
    } else {
      this.isChangedOpenWeatherTab = true;
      this.drawPreviewWhenWeatherDataChanged(contentDetail);
    }
  }

  /**
   * change select index word group
   *
   * @param contentDetail
   * @param iwGroupId
   */
  public changeSelectIndexWordGroup(contentDetail: any, iwGroupId: any) {
    contentDetail.indexWordGroup = iwGroupId ? this.indexWordGroups.find(iwg => iwg.id == iwGroupId) : null;
    if (this.isActiveWeatherTab) {
      this.isChangedWeatherTab = true;
    } else {
      this.isChangedOpenWeatherTab = true;
    }
    this.drawPreviewWhenWeatherDataChanged(contentDetail);
  }

  /**
   * change select index word group
   *
   * @param contentDetail
   * @param iwGroupId
   */
  public changeSelectCity(contentDetail: any, idCity: any) {
    contentDetail.city = idCity ? this.contentSelected?.cities.find(city => city.id == idCity) : null;
    this.isChangedOpenWeatherTab = true;
    this.drawPreviewWhenWeatherDataChanged(contentDetail);
  }

  /**
   * draw preview when source is changed
   *
   * @param contentDetail
   */
  private drawPreviewWhenWeatherDataChanged(contentDetail: any) {
    if (contentDetail.area.checkTypeTextArea()) {
      contentDetail.area.text = null;
    } else {
      contentDetail.area.media = null;
    }
    const areaOnCanvas = Helper.getAllAreaTemplate(this.contentSelected.template).find(
      areaOfTemplate => areaOfTemplate.id == contentDetail.area.id
    );
    this.drawAreaOntoCanvas(areaOnCanvas);
  }

  /**
   * save content
   */
  private save() {
    let error = undefined;
    if (!this.contentSelected) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.please-choose-content-selected');
    } else if (this.contentSelected.isEdit) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.editing-mode');
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.isActiveNewsTab || this.isActiveWeatherTab ? 'Error' : this.translateService.instant('dialog-error.title'),
          text: error
        },
        autoFocus: false
      });
      this.isCheckLoadOpenWeatherTab = true;
      return;
    }
    if (this.isActiveNewsTab) {
      if (this.contentSelected.newsContentDetails.length == 0) {
        return;
      }
      this.contentDetailSelected = undefined;
      this.highlightAreaPreviewSubject.next();
      this.pausePreviewSubject.next();
      this.newsContentDetailService.saveNewsContentDetail(this.contentSelected.newsContentDetails).subscribe(
        newsContentDetailsDataResponse => {
          this.contentSelected.newsContentDetails = newsContentDetailsDataResponse.map(
            Helper.convertDataNewsContentDetailFromServer.bind(Helper)
          );
          this.newsCurrentPage = 1;
          this.isChangedNewsTab = false;
          this.handleAfterSave();
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else if (this.isActiveWeatherTab) {
      if (this.contentSelected.weatherContentDetails.length == 0) {
        return;
      }
      if (this.validateBeforeSave(false)) {
        return;
      }
      this.contentDetailSelected = undefined;
      this.highlightAreaPreviewSubject.next();
      this.weatherContentDetailService
        .saveWeatherContentDetail(this.contentSelected.weatherContentDetails, this.contentSelected.id)
        .subscribe(
          weatherContentDetailsDataResponse => {
            this.contentSelected.weatherContentDetails = weatherContentDetailsDataResponse.map(
              Helper.convertDataWeatherContentDetailFromServer.bind(Helper)
            );
            this.isChangedWeatherTab = false;
            this.handleAfterSave();
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
    } else {
      if (this.validateBeforeSave(true)) {
        this.isCheckLoadOpenWeatherTab = true;
        return;
      }
      this.contentDetailSelected = undefined;
      this.highlightAreaPreviewSubject.next();
      const payload = {
        apiKey: this.apiDetails.apiKey,
        timezone: this.getTimezoneByOffset(Helper.getUserTimeZone(this.commonService.getCommonObject().setting)),
        renewalIntervalHour: this.apiCycle.interval,
        renewalStartTime: this.convertTimeToHour(this.apiCycle.startTime),
        renewalEndTime: this.convertTimeToHour(this.apiCycle.endTime)
      };
      this.weatherContentDetailService.callApiAWS('post', this.accountId, payload).subscribe(
        data => {
          console.log(data);
        },
        error => {
          console.log(error);
        }
      );
      this.weatherContentDetailService
        .saveOpenWeatherContentDetail(this.contentSelected.weatherContentDetails, this.contentSelected.id, this.apiDetails)
        .subscribe(
          weatherContentDetailsDataResponse => {
            this.contentSelected.weatherContentDetails = weatherContentDetailsDataResponse.map(
              Helper.convertDataOpenWeatherContentDetailFromServer.bind(Helper)
            );
            this.isChangedOpenWeatherTab = false;
            this.isCheckLoadOpenWeatherTab = false;
            this.handleAfterSave();
            this.weatherContentDetailService
              .callApiAwsEntry('put', this.accountId, this.contentSelected.contentOutputFileName, this.contentSelected.isAutoUpdate)
              .subscribe(
                data => {
                  console.log(data);
                },
                error => {
                  console.log(error);
                }
              );
            this.weatherContentDetailService
              .uploadAfterSave(this.contentSelected.id, this.contentSelected.contentOutputFileName, null)
              .subscribe(
                data => {
                  console.log(data);
                },
                error => {
                  this.handleErrorFromApi(error);
                }
              );
          },
          error => {
            this.handleErrorFromApi(error);
            this.isCheckLoadOpenWeatherTab = true;
          }
        );
    }
  }

  /**
   * convertTimeToHour
   * @param time
   * @returns
   */
  convertTimeToHour(time: string): number {
    const parts = time.split(':');
    return parseInt(parts[0], 10);
  }

  /**
   * validateBeforeSave
   */
  private validateBeforeSave(isOpenWeather: boolean): boolean {
    let min = isOpenWeather ? this.MIN_OPEN_FORECAST_PARAM : this.MIN_FORECAST_PARAM;
    let max = isOpenWeather
      ? this.isDayMode
        ? this.MAX_OPEN_FORECAST_PARAM_DAILY
        : this.MAX_OPEN_FORECAST_PARAM
      : this.MAX_FORECAST_PARAM;
    return !this.contentSelected.weatherContentDetails.every(weatherContentDetail => {
      let errMess = undefined;
      if (
        weatherContentDetail.area &&
        (isOpenWeather ||
          (!isOpenWeather && (weatherContentDetail.forecastParam != undefined || weatherContentDetail.forecastParam != null)))
      ) {
        if (weatherContentDetail.forecastParam === '' || weatherContentDetail.forecastParam < min) {
          errMess =
            this.isActiveNewsTab || this.isActiveWeatherTab
              ? Helper.getErrorMessage(ErrorEnum.MIN, this.propertiesName.forecastParam, min)
              : this.isDayMode
              ? this.translateService.instant('external-content-manager.msg.min-forecast-open-weather-daily')
              : this.translateService.instant('external-content-manager.msg.min-forecast-open-weather');
          this.inputFocusCurrent = this.forecastParamInput.nativeElement;
        } else if (weatherContentDetail.forecastParam > max) {
          errMess =
            this.isActiveNewsTab || this.isActiveWeatherTab
              ? Helper.getErrorMessage(ErrorEnum.MAX, this.propertiesName.forecastParam, max)
              : this.isDayMode
              ? this.translateService.instant('external-content-manager.msg.max-forecast-open-weather-daily')
              : this.translateService.instant('external-content-manager.msg.max-forecast-open-weather');
          this.inputFocusCurrent = this.forecastParamInput.nativeElement;
        } else if (weatherContentDetail.forecastParam == null) {
          errMess =
            this.isActiveNewsTab || this.isActiveWeatherTab
              ? Helper.getErrorMessage(ErrorEnum.MIN, this.propertiesName.forecastParam, min)
              : this.isDayMode
              ? this.translateService.instant('external-content-manager.msg.min-forecast-open-weather-daily')
              : this.translateService.instant('external-content-manager.msg.min-forecast-open-weather');
          this.inputFocusCurrent = this.forecastParamInput?.nativeElement;
        }
      }
      if (errMess) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.isActiveNewsTab || this.isActiveWeatherTab ? 'Error' : this.translateService.instant('dialog-error.title'),
              text: errMess
            },
            autoFocus: false
          },
          () => {
            this.inputFocusCurrent.focus();
          }
        );
        return false;
      }
      return true;
    });
  }

  /**
   * save before leave
   */
  private saveBeforeLeave() {
    if (!this.contentSelected || this.contentSelected.isEdit) {
      return;
    }
    if (this.isActiveNewsTab) {
      if (this.contentSelected.newsContentDetails.length == 0) {
        return;
      }
      this.newsContentDetailService.saveNewsContentDetail(this.contentSelected.newsContentDetails).subscribe(
        () => {
          this.isChangedNewsTab = false;
          this.saveDataSuccess.emit(true);
          this.toastService.success(this.translateService.instant('external-content-manager.save-successfully'), '');
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else if (this.isActiveWeatherTab) {
      if (this.validateBeforeSave(false)) {
        return;
      }
      this.weatherContentDetailService
        .saveWeatherContentDetail(this.contentSelected.weatherContentDetails, this.contentSelected.id)
        .subscribe(
          () => {
            this.isChangedWeatherTab = false;
            this.saveDataSuccess.emit(true);
            this.toastService.success(this.translateService.instant('external-content-manager.save-successfully'), '');
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
    } else {
      if (this.validateBeforeSave(true)) {
        return;
      }
      this.weatherContentDetailService
        .saveOpenWeatherContentDetail(this.contentSelected.weatherContentDetails, this.contentSelected.id, this.apiDetails)
        .subscribe(
          () => {
            this.isChangedOpenWeatherTab = false;
            this.saveDataSuccess.emit(true);
            this.toastService.success(this.translateService.instant('external-content-manager.save-successfully'), '');
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
    }
  }

  /**
   * handle after save
   */
  private handleAfterSave() {
    this.saveDataSuccess.emit(true);
    this.toastService.success(this.translateService.instant('external-content-manager.save-successfully'), '');
    this.drawPreviewForContent(this.contentSelected, true);
  }

  /**
   * create output file
   */
  private createOutputFile() {
    let error = undefined;
    if (!this.contentSelected) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.please-choose-content-selected');
    } else if (this.contentSelected.isEdit) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.editing-mode');
    } else if (this.contentSelected && !this.contentSelected.template) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.no-template');
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.isActiveNewsTab || this.isActiveWeatherTab ? 'Error' : this.translateService.instant('dialog-error.title'),
          text: error
        },
        autoFocus: false
      });
      return;
    }
    if (this.isActiveNewsTab) {
      this.newsContentService
        .createOutputFile(
          this.contentSelected.id,
          Helper.getReferencePositions(Helper.getAllAreaTemplate(this.contentSelected.template).filter(area => area.checkTypeTextArea()))
        )
        .subscribe(
          () => {
            this.toastService.success('Create output file successfully.', '');
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
    } else if (this.isActiveWeatherTab) {
      this.weatherContentService.createOutputFile(Helper.convertFullDataWeatherContentFromClient(this.contentSelected)).subscribe(
        () => {
          this.toastService.success('Create output file successfully.', '');
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      this.weatherContentService
        .createOutputFileOpenWeather(Helper.convertFullDataOpenWeatherContentFromClient(this.contentSelected))
        .subscribe(
          () => {
            this.toastService.success('Create output file successfully.', '');
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
    }
  }

  /**
   * change municipal code for weather content by focus out
   *
   * @param event
   */
  public changeMunicipalCodeFocusOut(event: any) {
    if (!this.isChangeMunicipalCode || event.relatedTarget?.id == 'btn-edit-save' || event.relatedTarget?.id == 'btn-edit-cancel') {
      return;
    }
    this.municipalService.getMunicipalByCode(this.contentSelected.municipalCode).subscribe(data => {
      this.contentSelected.municipalName = data ? `${data.cityName}${data.municipalName}` : null;
      this.isChangeMunicipalCode = false;
    });
  }

  /**
   * change municipal code by enter key
   *
   * @param event
   */
  public changeMunicipalCodeEnterKey(event: any) {
    if (!this.isChangeMunicipalCode || !event) {
      return;
    }
    // key enter
    if (event.keyCode == this.ENTER_KEY_CODE) {
      this.municipalService.getMunicipalByCode(this.contentSelected.municipalCode).subscribe(data => {
        this.contentSelected.municipalName = data ? `${data.cityName}${data.municipalName}` : null;
        this.isChangeMunicipalCode = false;
      });
    }
  }

  /**
   * enlarge preview
   */
  public enlargePreview() {
    this.isEnlargePreview = !this.isEnlargePreview;
    if (!this.contentSelected) {
      return;
    }
    if (this.contentSelected.template) {
      this.calculateScaleTransformCanvas(this.contentSelected.template);
    }
  }

  /**
   * play preview
   */
  public playPreview() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.isPlayPreview = true;
    if (this.newsCurrentPage == this.contentSelected.pageCounts) {
      this.newsCurrentPage = 1;
      this.drawLinkTextAtNewsContent();
    }
    if (!this.contentSelected.duration || this.contentSelected.pageCounts == 1) {
      return;
    }
    this.resetTimerPreviewSubject
      .pipe(
        startWith(0),
        switchMap(() => timer(this.contentSelected.duration * 1000, this.contentSelected.duration * 1000)),
        takeUntil(this.pausePreviewSubject)
      )
      .subscribe(() => {
        if (this.newsCurrentPage == this.contentSelected.pageCounts) {
          this.pausePreviewSubject.next();
          return;
        }
        this.newsCurrentPage++;
        this.drawLinkTextAtNewsContent();
      });
  }

  /**
   * pause preview
   */
  public pausePreview() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.pausePreviewSubject.next();
  }

  /**
   * reset preview
   */
  public resetPreview() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    if (this.newsCurrentPage == 1) {
      return;
    }
    this.newsCurrentPage = 1;
    this.drawLinkTextAtNewsContent();
    if (this.isPlayPreview) {
      this.pausePreviewSubject.next();
    }
  }

  /**
   * next preview
   */
  public nextPreview() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    if (this.newsCurrentPage == this.contentSelected.pageCounts) {
      return;
    }
    this.newsCurrentPage++;
    this.drawLinkTextAtNewsContent();
    if (this.isPlayPreview) {
      this.resetTimerPreviewSubject.next();
    }
  }

  /**
   * prev preview
   */
  public prevPreview() {
    let error = undefined;
    if (!this.contentSelected) {
      error = Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content);
    } else if (this.contentSelected.isEdit) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content);
    } else if (this.contentSelected && !this.contentSelected.template) {
      error = Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content);
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: 'Error',
          text: error
        },
        autoFocus: false
      });
      return;
    }
    if (this.newsCurrentPage == 1) {
      return;
    }
    this.newsCurrentPage--;
    this.drawLinkTextAtNewsContent();
    if (this.isPlayPreview) {
      this.resetTimerPreviewSubject.next();
    }
  }

  /**
   * draw link text at news content
   */
  private drawLinkTextAtNewsContent() {
    let areas = Helper.getAllAreaTemplate(this.contentSelected.template).filter(area => area.checkTypeTextArea() && !area.isFix);
    Promise.all(
      areas.map(area => {
        this.drawTextAreaForContent(<TextArea>area);
      })
    );
  }

  /**
   * reload content data
   */
  public reloadContentData() {
    let error = undefined;
    if (!this.contentSelected) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.NON_SELECT, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.please-choose-content-selected');
    } else if (this.contentSelected.isEdit) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.PROPERTY_IS_EDITING, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.editing-mode');
    } else if (this.contentSelected && !this.contentSelected.template) {
      error =
        this.isActiveNewsTab || this.isActiveWeatherTab
          ? Helper.getErrorMessage(ErrorEnum.PROPERTY_HAS_NO_TEMPLATE, this.propertiesName.content)
          : this.translateService.instant('external-content-manager.msg.no-template');
    } else if (this.isPlayPreview) {
      error = Helper.getErrorMessage(ErrorEnum.PREVIEW_IS_PLAYING);
      this.pausePreviewSubject.next();
    }
    if (error) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.isActiveNewsTab || this.isActiveWeatherTab ? 'Error' : this.translateService.instant('dialog-error.title'),
          text: error
        },
        autoFocus: false
      });
      return;
    }
    this.contentDetailSelected = undefined;
    this.highlightAreaPreviewSubject.next();
    this.isReloadContentData = true;
    this.changeDetectorRef.detectChanges();
    this.ldsRing.nativeElement.style.top =
      (this.divPreview.nativeElement.clientHeight - this.ldsRing.nativeElement.clientHeight) / 2 + 'px';
    this.ldsRing.nativeElement.style.left = (this.divPreview.nativeElement.clientWidth - this.ldsRing.nativeElement.clientWidth) / 2 + 'px';
    if (this.isActiveNewsTab) {
      if (this.contentSelected.newsContentDetails.length == 0) {
        this.isReloadContentData = false;
        return;
      }
      this.newsContentDetailService.reloadNewsContentDetails(this.contentSelected.newsContentDetails).subscribe(
        newsContentDetailsDataResponse => {
          this.contentSelected.newsContentDetails = newsContentDetailsDataResponse.map(
            Helper.convertDataNewsContentDetailFromServer.bind(Helper)
          );
          this.newsCurrentPage = 1;
          this.drawPreviewForContent(this.contentSelected, true);
          this.isReloadContentData = false;
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else if (this.isActiveWeatherTab) {
      if (this.contentSelected.weatherContentDetails.length == 0) {
        this.isReloadContentData = false;
        return;
      }
      this.weatherContentDetailService.reloadWeatherContentDetails(this.contentSelected.weatherContentDetails).subscribe(
        weatherContentDetailsDataResponse => {
          this.contentSelected.weatherContentDetails = weatherContentDetailsDataResponse.map(
            Helper.convertDataWeatherContentDetailFromServer.bind(Helper)
          );
          this.drawPreviewForContent(this.contentSelected, true);
          this.isReloadContentData = false;
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    } else {
      if (this.contentSelected.weatherContentDetails.length == 0) {
        this.isReloadContentData = false;
        return;
      }
      this.openWeatherContentService.autoCallAPIWeather(this.apiDetails, false, this.contentSelected.id).subscribe(
        data => {
          this.contents = Helper.convertDataOpenWeatherContents(data);
          if (!this.contents || this.contents.length == 0) {
            this.dataService.sendData([Constant.HAS_CONTENT, false]);
          } else {
            this.dataService.sendData([Constant.HAS_CONTENT, true]);
          }
          const index = this.contents.findIndex(content => this.contentSelected.id == content.id);
          this.selectContent(this.contents[index]);
          this.isReloadContentData = false;
        },
        error => {
          this.handleErrorFromApi(error);
        }
      );
    }
  }

  /**
   * create all canvas area template
   *
   * @param template
   */
  private createAllCanvasAreaTemplate(template: Template) {
    const areas = Helper.getAllAreaTemplate(template);
    areas.forEach(area => {
      this.createCanvasArea(area);
    });
  }

  /**
   * create canvas area
   *
   * @param area
   */
  private createCanvasArea(area: Area) {
    const canvas = this.renderer.createElement('canvas');
    canvas.id = `previewCanvas-${area.id}`;
    canvas.style.position = 'absolute';
    canvas.style.zIndex = area.index;
    canvas.style.left = area.posX + 'px';
    canvas.style.top = area.posY + 'px';
    canvas.style.width = area.width + 'px';
    canvas.style.height = area.height + 'px';
    this.highlightAreaPreviewSubject.subscribe(() => {
      canvas.style.outline = this.contentDetailSelected?.area && this.contentDetailSelected?.area.id == area.id ? '7px dotted red' : 'none';
    });
    canvas.width = area.width;
    canvas.height = area.height;
    this.renderer.appendChild(this.divContainCanvas.nativeElement, canvas);
    area.canvas = canvas;
  }

  /**
   * draw preview content
   *
   * @param content
   * @param isOnlyDrawLinkArea
   */
  private drawPreviewForContent(content: any, isOnlyDrawLinkArea: boolean) {
    const areas = isOnlyDrawLinkArea
      ? Helper.getAllAreaTemplate(content.template).filter(area => !area.isFix)
      : Helper.getAllAreaTemplate(content.template);
    Promise.all(
      areas.map(area => {
        this.drawAreaOntoCanvas(area);
      })
    );
  }

  /**
   * draw text area for content
   *
   * @param area
   */
  private drawTextAreaForContent(textArea: TextArea) {
    if (!textArea.isFix) {
      textArea.text = this.getTextDataForLinkText(textArea);
    }
    if (textArea.text) {
      this.drawService.drawAreaText(textArea);
    } else {
      let ctx = textArea.canvas.getContext('2d');
      ctx.clearRect(0, 0, textArea.canvas.width, textArea.canvas.height);
      ctx.fillStyle = textArea.backgroundColor;
      ctx.fillRect(0, 0, textArea.canvas.width, textArea.canvas.height);
    }
  }

  /**
   * draw picture area for content
   *
   * @param area
   */
  private drawPictureAreaForContent(pictureArea: PictureArea) {
    if (!this.isActiveNewsTab && !pictureArea.isFix) {
      pictureArea.media = this.getPictureDataForLinkPicture(pictureArea);
    }
    this.drawPictureArea(pictureArea);
  }

  /**
   * draw area onto canvas
   *
   * @param content
   * @param area
   * @returns
   */
  private drawAreaOntoCanvas(area: Area) {
    if (!area) {
      return;
    }
    if (area.checkTypeTextArea()) {
      this.drawTextAreaForContent(<TextArea>area);
    } else {
      this.drawPictureAreaForContent(<PictureArea>area);
    }
  }

  /**
   * set text data for link text
   *
   * @param textArea
   */
  private getTextDataForLinkText(textArea: TextArea) {
    let text = undefined;
    if (this.isActiveNewsTab) {
      const newsContentDetail = this.contentSelected.newsContentDetails.find(contentDetail => contentDetail.area?.id == textArea.id);
      if (newsContentDetail?.source != null && newsContentDetail.texts) {
        text = newsContentDetail.texts[this.newsCurrentPage - 1] ?? undefined;
      }
    } else if (this.isActiveWeatherTab) {
      const weatherContentDetail = this.contentSelected.weatherContentDetails.find(contentDetail => contentDetail.area?.id == textArea.id);
      switch (weatherContentDetail?.source) {
        case WeatherContentSourceEnum.WEATHER_LOCATION:
          text = weatherContentDetail.area.text ?? undefined;
          break;
        case WeatherContentSourceEnum.DATE_TIME_MM_DD:
        case WeatherContentSourceEnum.DATE_TIME_HH:
        case WeatherContentSourceEnum.TEMP:
          if (weatherContentDetail.forecastParam) {
            text = weatherContentDetail.area.text ?? undefined;
          }
          break;
        default:
          break;
      }
    } else {
      const weatherContentDetail = this.contentSelected.weatherContentDetails.find(contentDetail => contentDetail.area?.id == textArea.id);
      if (weatherContentDetail?.source || weatherContentDetail?.source == 0) {
        text = weatherContentDetail.area.text ?? undefined;
        if (weatherContentDetail?.source == 0 || weatherContentDetail?.source == 1) {
          if (Number(text) < 10) {
            text = text.replace('0', '');
          }
        }
      }
    }
    return text;
  }

  /**
   * set text data for link text
   *
   * @param pictureArea
   */
  private getPictureDataForLinkPicture(pictureArea: PictureArea) {
    let media = undefined;
    const weatherContentDetail = this.contentSelected.weatherContentDetails.find(contentDetail => contentDetail.area?.id == pictureArea.id);
    if (weatherContentDetail?.source != null && weatherContentDetail?.forecastParam && weatherContentDetail?.indexWordGroup) {
      media = weatherContentDetail.area.media ?? undefined;
    }
    return media;
  }

  /**
   * draw fix picture area
   *
   * @param area
   * @returns
   */
  private drawPictureArea(area: PictureArea) {
    if (!area.media) {
      return;
    }
    let ctx = area.canvas.getContext('2d');
    ctx.clearRect(0, 0, area.width, area.height);
    let imageHtmlElement = document.createElement('img');
    imageHtmlElement.src = area.media.url;
    let pattern = ctx.createPattern(imageHtmlElement, 'no-repeat');
    ctx.fillStyle = pattern;
    const mediaPosition = Helper.coverMedia(area.canvas, area.media, area.objectFit);
    // listen event load
    imageHtmlElement.addEventListener('load', () => {
      // draw image
      if (area.objectFit == ObjectFitEnum.FILL) {
        ctx.drawImage(imageHtmlElement, mediaPosition.x, mediaPosition.y, mediaPosition.width, mediaPosition.height);
      } else {
        ctx.drawImage(
          imageHtmlElement,
          mediaPosition.sX,
          mediaPosition.sY,
          mediaPosition.sWidth,
          mediaPosition.sHeight,
          mediaPosition.x,
          mediaPosition.y,
          mediaPosition.width,
          mediaPosition.height
        );
      }
    });
  }

  /**
   * calculate scale transform canvas
   *
   * @param template
   */
  private calculateScaleTransformCanvas(template: Template) {
    this.changeDetectorRef.detectChanges();
    const maxHeight = this.divPreview.nativeElement.clientHeight - 18;
    const maxWidth = this.divPreview.nativeElement.clientWidth;
    let scaleTransform = { scaleX: 1, scaleY: 1 };
    if (template.width > maxWidth) {
      scaleTransform.scaleX = maxWidth / template.width;
    }
    if (template.height > maxHeight) {
      scaleTransform.scaleY = maxHeight / template.height;
    }
    const scale = Math.min(scaleTransform.scaleX, scaleTransform.scaleY);
    this.renderer.setStyle(this.divContainCanvas.nativeElement, 'transform', 'scale(' + scale + ')');
    const realHeightTemplate = template.height * scale;
    const realWidthTemplate = template.width * scale;
    const height = (maxHeight - realHeightTemplate) / 2;
    const width = (maxWidth - realWidthTemplate) / 2;
    this.divContainCanvas.nativeElement.style.marginTop = height + 'px';
    this.divContainCanvas.nativeElement.style.marginLeft = width + 'px';
    if (this.panzoomDisplay) {
      this.panzoomDisplay.reset({
        startScale: scale,
        minScale: 0.1,
        maxScale: 2,
        startX: 0,
        startY: 0
      });
    } else {
      this.panzoomDisplay = Panzoom(this.divContainCanvas?.nativeElement, { startScale: scale, minScale: 0.1, maxScale: 2 });
    }
    this.panzoomDisplay.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
    if (this.isReloadContentData) {
      this.ldsRing.nativeElement.style.top = (maxHeight - this.ldsRing.nativeElement.clientHeight) / 2 + 'px';
      this.ldsRing.nativeElement.style.left = (maxWidth - this.ldsRing.nativeElement.clientWidth) / 2 + 'px';
    }
    if (this.isActiveNewsTab && this.divNewsPages) {
      this.divNewsPages.nativeElement.style.top = height + realHeightTemplate + 3 + 'px';
    }
    this.renderer.setStyle(this.divContainCanvas.nativeElement, 'cursor', 'default');
  }

  /**
   * key down event
   *
   * @param e
   */
  @HostListener('document:keydown', ['$event'])
  keyDown(e) {
    if (e.keyCode in this.keyCodeMap) {
      this.keyCodeMap[e.keyCode] = true;
      // save shortcut
      if (this.keyCodeMap[17] && this.keyCodeMap[18] && this.keyCodeMap[83]) {
        this.save();
      }
    }
  }

  /**
   * key up event
   * @param e
   */
  @HostListener('document:keyup', ['$event'])
  keyUp(e) {
    if (this.keyCodeMap[e.keyCode]) {
      this.keyCodeMap[e.keyCode] = false;
    }
  }

  /**
   * handle error from Api
   *
   * @param error
   */
  private handleErrorFromApi(error: any) {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: `Error`,
        text: `An error has occurred. Please try again.`
      }
    });
  }

  /**
   * on change municipal code input
   *
   * @param input
   */
  public onChangeMunicipalCodeInput(input: any) {
    this.isChangeMunicipalCode = true;
    const value = input.value;
    const numbers = value.replace(/[^0-9]/g, '');
    input.value = numbers;
    this.contentSelected.municipalCode = numbers;
  }

  /**
   * on change number input
   *
   * @param input
   * @param properties
   * @returns
   */
  public onChangeNumberInput(input: any, properties: any) {
    const value = input.value;
    const numbers = value.replace(/[^0-9]/g, '');
    input.value = numbers;
    properties = numbers;
  }

  /**
   * on change forecast param input
   *
   * @param input
   * @param properties
   */
  public onChangeForecastParamInput(input: any, weatherDetail: any) {
    const value = input.value;
    const numbers = value.replace(/[^0-9]/g, '');
    input.value = numbers;
    weatherDetail.forecastParam = numbers;
    this.drawPreviewWhenWeatherDataChanged(weatherDetail);
  }

  /**
   * scroll select row
   */
  private scrollIntoViewSelected(elementRef: ElementRef, index: number): void {
    _.get(elementRef, `nativeElement.children[${index}]`, elementRef)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  /**
   * import city
   */
  public importCity(): void {
    if (this.handerEditingContent()) {
      return;
    }
    let element = document.getElementById('importedFileCity') as HTMLInputElement;
    element.setAttribute('accept', '.xlsx');
    element.click();
  }

  /**
   * upload excel
   */
  public uploadExcel(event): Promise<void> {
    let selectedFile: File = event.target.files[0];
    const typeFiles = ['xlsx'];
    let typeName = selectedFile.name.slice(selectedFile.name.lastIndexOf('.') + 1, selectedFile.name.length).toLowerCase();
    if (!typeFiles.includes(typeName)) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('external-content-manager.msg.invalid-file')
        }
      });
      return;
    }
    this.openPopupConfirm(selectedFile);
  }

  /**
   * open popup confirm when import timetable
   *
   * @param timetablesData
   */
  private openPopupConfirm(selectedFile: File): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('external-content-manager.confirmation.text'),
          button1: this.translateService.instant('external-content-manager.confirmation.overwrite'),
          button2: this.translateService.instant('external-content-manager.confirmation.cancel'),
          button3: this.translateService.instant('external-content-manager.confirmation.add')
        }
      },
      result => {
        if (!result) {
          return;
        }
        let action = result == Constant.ADD_CONFIRMATION ? Constant.ADD_CONFIRMATION : Constant.OVERWRITE_CONFIRMATION;
        if (action == Constant.OVERWRITE_CONFIRMATION) {
          let isContainCity = this.contents.some(content => content.cities && content.cities?.length != 0);
          if (isContainCity) {
            this.dialogService.showDialog(
              DialogConfirmComponent,
              {
                data: {
                  text: this.translateService.instant('external-content-manager.confirmation.continue-if-deleted'),
                  button1: this.translateService.instant('external-content-manager.confirmation.yes'),
                  button2: this.translateService.instant('external-content-manager.confirmation.no')
                }
              },
              result => {
                if (!result) {
                  this.openPopupConfirm(selectedFile);
                } else {
                  this.openWeatherContentService.readExcelCities(selectedFile, action).subscribe(
                    data => {
                      this.contents.forEach(content => {
                        content.cities = [];
                      });
                      this.selectContent(this.contentSelected);
                      this.cityListFromImport = data;
                    },
                    error => {
                      this.handleShowErrorMessageFromServerWhenImport(error);
                    }
                  );
                }
              }
            );
          } else {
            this.openWeatherContentService.readExcelCities(selectedFile, action).subscribe(
              data => {
                this.cityListFromImport = data;
              },
              error => {
                this.handleShowErrorMessageFromServerWhenImport(error);
              }
            );
          }
        } else {
          this.openWeatherContentService.readExcelCities(selectedFile, action).subscribe(
            data => {
              this.cityListFromImport = data;
            },
            error => {
              this.handleShowErrorMessageFromServerWhenImport(error);
            }
          );
        }
      }
    );
    let element = document.getElementById('importedFileCity') as HTMLInputElement;
    element.value = null;
  }

  /**
   * set api call cycle
   */
  public setAPICallCycle(): void {
    if (this.handerEditingContent()) {
      return;
    }
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_CALL_API_CYCLE).subscribe(result => {
      this.dialogService.showDialog(
        DialogCallApiCycleComponent,
        {
          data: {
            commonTable: result ? result : new CommonTable(Constant.KEY_CALL_API_CYCLE, JSON.stringify(new APICycle('', '', null)))
          }
        },
        result => {
          if (result) {
            this.apiCycle = JSON.parse(result.value);
            const payload = {
              apiKey: this.apiDetails.apiKey,
              timezone: this.getTimezoneByOffset(Helper.getUserTimeZone(this.commonService.getCommonObject().setting)),
              renewalIntervalHour: this.apiCycle.interval,
              renewalStartTime: this.convertTimeToHour(this.apiCycle.startTime),
              renewalEndTime: this.convertTimeToHour(this.apiCycle.endTime)
            };
            this.weatherContentDetailService.callApiAWS('post', this.accountId, payload).subscribe(
              data => {
                console.log(data);
              },
              error => {
                console.log(error);
              }
            );
          }
        }
      );
    });
  }

  /**
   * set api details
   */
  public setAPIDetails(): void {
    if (this.handerEditingContent()) {
      return;
    }
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_API_DETAILS).subscribe(result => {
      this.dialogService.showDialog(
        DialogApiDetailsComponent,
        {
          data: {
            commonTable: result ? result : new CommonTable(Constant.KEY_API_DETAILS, JSON.stringify(new APIDetails(null, null)))
          }
        },
        result => {
          if (result) {
            this.apiDetails = JSON.parse(result.value);
            const payload = {
              apiKey: this.apiDetails.apiKey,
              timezone: this.getTimezoneByOffset(Helper.getUserTimeZone(this.commonService.getCommonObject().setting)),
              renewalIntervalHour: this.apiCycle.interval,
              renewalStartTime: this.convertTimeToHour(this.apiCycle.startTime),
              renewalEndTime: this.convertTimeToHour(this.apiCycle.endTime)
            };
            this.weatherContentDetailService.callApiAWS('post', this.accountId, payload).subscribe(
              data => {
                console.log(data);
              },
              error => {
                console.log(error);
              }
            );
          }
        }
      );
    });
  }

  /**
   * export city format
   * @returns
   */
  async exportCityFormat(): Promise<any> {
    if (this.handerEditingContent()) {
      return;
    }
    // check network
    let errorText = await this.commonService
      .checkNetWorkBeforeSave()
      .toPromise()
      .catch(error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          return Constant.NETWORK_ERROR_CODE;
        }
      });
    if (errorText == Constant.NETWORK_ERROR_CODE) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('dialog-error.error-network-api')
        }
      });
      return;
    }

    this.openWeatherContentService.writeExcelCity().subscribe(
      data => {
        const fileNameResponse = decodeURIComponent(data.headers.get('content-disposition'));
        const file = new File([data.body], fileNameResponse, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        fileSaver.saveAs(file);
      },
      error => {
        this.handleShowErrorMessageFromServerWhenImport(error);
      }
    );
  }

  /**
   * Handle show error message from server when import
   *
   * @param error
   */
  private handleShowErrorMessageFromServerWhenImport(error: any): void {
    switch (error.error?.detail) {
      case Constant.ERROR_EMPTY_CITY:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('external-content-manager.msg.error-empty-city')
          }
        });
        break;
      case Constant.ERROR_EMPTY_LATITUDE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('external-content-manager.msg.error-empty-latitude')
          }
        });
        break;
      case Constant.ERROR_EMPTY_LONGITUDE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('external-content-manager.msg.error-empty-longitude')
          }
        });
        break;
      case Constant.ERROR_FORMAT_LATITUDE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('external-content-manager.msg.error-format-latitude')
          }
        });
        break;
      case Constant.ERROR_FORMAT_LONGITUDE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('external-content-manager.msg.error-format-longitude')
          }
        });
        break;
      case Constant.ERROR_NO_START_CODE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('external-content-manager.msg.error-no-start-code')
          }
        });
        break;
      case Constant.ERROR_FORMAT_HEADER:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('external-content-manager.msg.error-format-header')
          }
        });
        break;
      case Constant.ERROR_START_CODE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('external-content-manager.msg.error-start-code')
          }
        });
        break;
      case Constant.ERROR_EXISTS_NAME_TIMETABLE:
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            texts: [
              this.translateService.instant('external-content-manager.msg.duplicate-city-name1'),
              this.translateService.instant('external-content-manager.msg.duplicate-city-name2')
            ]
          }
        });
        break;
    }
  }

  /**
   * choose city
   */
  public chooseCity(): void {
    if (this.handerEditingContent()) {
      return;
    }
    if (!this.contentSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('external-content-manager.msg.please-choose-content-selected')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogChooseCityComponent,
      {
        data: {
          cityListOfContent: this.contentSelected.cities,
          cityListFromImport: this.cityListFromImport
        }
      },
      result => {
        if (result == undefined) {
          return;
        }
        this.openWeatherContentService.saveOpenWeatherContent(Helper.convertDataOpenBackward(this.contentSelected), result).subscribe(
          weatherContentDataResponse => {
            let isSave = false;
            this.contentSelected.cities = weatherContentDataResponse.cities;
            const nameCites = this.contentSelected.cities.map(city => city.name);
            if (this.contentSelected.weatherContentDetails && this.contentSelected.weatherContentDetails.length) {
              this.contentSelected.weatherContentDetails.forEach(item => {
                if (!nameCites.includes(item?.city?.name)) {
                  item.city = undefined;
                  isSave = true;
                }
              });
            }

            if (isSave) {
              this.save();
            }
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
      }
    );
  }

  /**
   * settingAutoUpdate
   */
  public settingAutoUpdate(): void {
    if (!this.contentSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('external-content-manager.msg.please-choose-content-selected')
        }
      });
      return;
    }
    if (!this.contentSelected.id) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('external-content-manager.msg.output-file-not-saved')
        }
      });
      return;
    }
    if (!this.apiCycle || !this.apiCycle.interval || !this.apiDetails || !this.apiDetails.apiKey) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('external-content-manager.msg.setting-not-completed')
        }
      });
      return;
    }
    if (this.isChangedOpenWeatherTab || this.contentSelected?.isEdit) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('layout.confirm-save'),
            button1: this.translateService.instant('layout.yes'),
            button2: this.translateService.instant('layout.no')
          }
        },
        result => {
          if (!result) {
            if (this.isChangedOpenWeatherTab) {
              this.weatherContentService.getOpenWeatherContentWithFullDataTemplateById(this.contentSelected.id).subscribe(
                weatherContentDataResponse => {
                  this.setDataForContent(weatherContentDataResponse, false);
                  this.weatherContentDetailService
                    .getOpenWeatherContentDetailsByContentId(this.contentSelected.id, this.apiDetails)
                    .subscribe(
                      weatherContentDetailsDataResponse => {
                        this.contentSelected.weatherContentDetails = weatherContentDetailsDataResponse.map(
                          Helper.convertDataOpenWeatherContentDetailFromServer.bind(Helper)
                        );
                        this.isChangedOpenWeatherTab = false;
                        this.drawPreviewForContent(this.contentSelected, false);
                        this.areaDisabledOptions = this.contentSelected.weatherContentDetails.map(
                          weatherContentDetail => weatherContentDetail.area
                        );
                      },
                      error => {
                        this.handleErrorFromApi(error);
                      }
                    );
                  this.showDialogAutoUpdate();
                },
                error => {
                  this.handleErrorFromApi(error);
                }
              );
            }
            if (this.contentSelected?.isEdit) {
              this.cancelEditContent();
              this.showDialogAutoUpdate();
            }
          } else {
            if (this.isChangedOpenWeatherTab) {
              this.save();
            }
            if (this.contentSelected?.isEdit) {
              this.saveEditContent(false);
            }
            this.showDialogAuto();
          }
        }
      );
    } else {
      this.showDialogAutoUpdate();
    }
  }

  private showDialogAuto() {
    setTimeout(() => {
      if (!this.isChangedOpenWeatherTab && !this.contentSelected.isEdit) {
        this.showDialogAutoUpdate();
      } else if (!this.isCheckLoadOpenWeatherTab) {
        this.showDialogAuto();
      }
    }, 1000);
  }
  /**
   * showDialogAutoUpdate
   */
  private showDialogAutoUpdate(): void {
    this.dialogService.showDialog(
      DialogSettingAutoUpdateComponent,
      {
        data: {
          content: this.contentSelected
        }
      },
      result => {
        if (!result) {
          return;
        }
        this.openWeatherContentService.saveOpenWeatherContent(Helper.convertDataOpenBackward(result)).subscribe(
          weatherContentDataResponse => {
            this.contentSelected.isAutoUpdate = weatherContentDataResponse['autoUpdate'];
            this.weatherContentDetailService
              .callApiAwsEntry('put', this.accountId, this.contentSelected.contentOutputFileName, this.contentSelected.isAutoUpdate)
              .subscribe(
                data => {
                  //console.log(data);
                },
                error => {
                  console.log(error);
                }
              );
            if (weatherContentDataResponse['autoUpdate']) {
              this.openWeatherContentService.autoCallAPIWeather(this.apiDetails, false, this.contentSelected.id).subscribe(data => {
                this.contents = Helper.convertDataOpenWeatherContents(data);
                if (!this.contents || this.contents.length == 0) {
                  this.dataService.sendData([Constant.HAS_CONTENT, false]);
                } else {
                  this.dataService.sendData([Constant.HAS_CONTENT, true]);
                }
                const index = this.contents.findIndex(content => this.contentSelected.id == content.id);
                this.selectContent(this.contents[index]);
              });
              if (this.contents.every(content => !content.isAutoUpdate)) {
                this.timeOutBeginStartCall = setTimeout(() => {
                  this.startAutoCallApiSubject.next();
                  this.autoCallAPIWeather(false);
                }, this.calculatorTimeStartCall());
              }
            }
            if (!weatherContentDataResponse['autoUpdate']) {
              const index = this.contents.findIndex(content => content.id == this.contentSelected.id);
              let contentsClone = _.cloneDeep(this.contents);
              let contentsTempl = contentsClone.splice(index, 1);
              if (contentsTempl.every(element => !element.isAutoUpdate)) {
                this.clearAllOpenWeather();
              }
            }
          },
          error => {
            this.handleErrorFromApi(error);
          }
        );
      }
    );
  }
  /**
   * show Cities Name
   * @param cities
   * @returns
   */
  public showCitiesName(cities: Array<City>): string {
    let citiesName = '';
    if (!cities || !cities.length) {
      return citiesName;
    }
    for (let i = 0; i < cities.length; i++) {
      citiesName = citiesName.concat((i + 1).toString() + ':' + cities[i]?.name);
      if (i < cities.length - 1) {
        citiesName = citiesName.concat('、');
      }
    }
    return citiesName;
  }

  /**
   * hander editing content
   * @returns
   */
  public handerEditingContent(): boolean {
    let isEditingContent = false;
    if (this.contentSelected?.isEdit) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('external-content-manager.error'),
          text: this.translateService.instant('external-content-manager.msg.editing-mode')
        },
        autoFocus: false
      });
      isEditingContent = true;
    }
    return isEditingContent;
  }

  /**
   * choose tool
   *
   * @param tool
   */
  public chooseTool(tool: PreviewToolEnum): void {
    if (!this.contentSelected) {
      return;
    }
    if (tool == PreviewToolEnum.PAN) {
      this.isPan = !this.isPan;
      this.renderer.setStyle(this.divContainCanvas?.nativeElement, 'cursor', this.isPan ? 'move' : 'default');
    } else {
      this.isZoom = !this.isZoom;
      this.renderer.setStyle(this.divContainCanvas?.nativeElement, 'cursor', this.isPan ? 'move' : 'default');
    }
    if (this.isZoom && this.isPan) {
      this.renderer.setStyle(this.divContainCanvas?.nativeElement, 'cursor', 'move');
    }
    this.panzoomDisplay.setOptions({ disablePan: !this.isPan, disableZoom: !this.isZoom, force: true });
  }

  /**
   * subscribe event mouse wheel
   *
   * @param e
   * @returns
   */
  @HostListener('mousewheel', ['$event'])
  mouseWheel(e) {
    if (!this.isZoom) {
      return;
    }
    if (e.target.id.includes('previewCanvas')) {
      this.panzoomDisplay.zoomWithWheel(e);
    }
  }

  /**
   * changeSelectInterval
   * @param event
   * @param i
   * @param content
   */
  changeSelectInterval(event: any, i: number, content: any): void {
    const previousInterval = content.interval;
    if (event === null || event === undefined) {
      this.setOptionDefault(i, {
        id: previousInterval,
        name: previousInterval != null ? this.linkTextIntervals[previousInterval].name : null
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('external-content-manager.confirmation.change-select-interval'),
          button1: this.translateService.instant('external-content-manager.confirmation.yes'),
          button2: this.translateService.instant('external-content-manager.confirmation.no')
        }
      },
      result => {
        if (!result) {
          this.setOptionDefault(i, {
            id: previousInterval,
            name: previousInterval != null ? this.linkTextIntervals[previousInterval].name : null
          });
          return;
        }
        content.interval = event;
        this.isDayMode = event == 1;
        this.contentSelected.interval = event;
        this.contentSelected.weatherContentDetails = [];
        this.areaDisabledOptions = [];
        this.saveEditContent(true);
      }
    );
  }

  /**
   * setOptionDefault
   * @param index
   * @param option
   */
  setOptionDefault(index: number, option: any): void {
    const selectBox = this.selectBoxes.toArray()[index];
    if (selectBox) {
      selectBox.selection = option;
    }
  }

  /**
   * getNameOption
   * @param list
   * @param id
   * @returns
   */
  getNameOption(list: any, id: number): string {
    if (!list || Helper.isEmpty(id)) {
      return '';
    }
    const index = list.findIndex(e => e.id == id);
    if (index == -1) {
      return '';
    }
    return list[index].name;
  }

  /**
   * parseTimezoneOffset
   * @param offset
   * @returns
   */
  parseTimezoneOffset(offset: string): string {
    const match = offset.match(/^([+-])(\d{1,2}):(\d{2})$/);
    if (match) {
      const sign = match[1] === '+' ? 1 : -1;
      const hours = parseInt(match[2], 10);
      const offsetNumber = sign * hours;
      return offsetNumber.toString();
    }
    return '0';
  }

  /**
   * getTimezoneByOffset
   * @param offset
   * @returns
   */
  getTimezoneByOffset(offset: string): string {
    const offsetString = this.parseTimezoneOffset(offset);
    return this.offsetToTimezone[offsetString] || 'Asia/Tokyo';
  }
}
