import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, SortTypeEnum } from 'app/config/constants';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { UserLocation } from 'app/model/entity/fuji-climber-management/user-location';
import { IHash, OptionFilter } from 'app/model/entity/sort-filter-object';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription } from 'rxjs';
import { FujiClimberManagementService } from './../../../service/fuji-climber-management.service';

@Component({
  selector: 'tab-climber-location-information',
  templateUrl: './tab-climber-location-information.component.html',
  styleUrls: ['./tab-climber-location-information.component.scss']
})
export class TabClimberLocationInformationComponent implements OnInit {
  @Input() informationAccount: any;
  @Input() tabSelected: number; // tab selected
  private commonObject: Common; // Common object
  languageKey: string;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  placeholderSearch: string; // Set text placeholder input search
  config: any;
  Helper = Helper;
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  dataSearch = {
    startDate: undefined,
    endDate: undefined,
    appId: '-1',
    saleTicketId: '-1',
    orderId: '',
    orderDistributionId: '',
    recipientName: '',
    phoneNumber: '',
    email: ''
  };
  listApp: ApplicationDTO[] = []; // list Application
  listTicketSale: any[] = [];
  allTicketSaleList: any[] = [];
  listOfClimbersDisplay: any[] = [];
  dataFilterOrder: any[];
  dataFilterOrderConvert: any[];
  isSortFilter: boolean;
  isCheckAllOptionFilter: boolean;
  columnSortFiltering: string;
  isShowPopUpSortFilter: boolean;
  lastColumnFilter: string;
  listFilterDisplay: Array<OptionFilter>;
  listFilterDisplayOriginal: Array<OptionFilter>;
  isFilter: boolean;
  isClear: boolean;
  listCurrentFilter: IHash = {};
  listSorted: any = [];
  isCheckedAllClimbers: boolean;
  isSearching = false; // Status searching
  climberSelected: any;

  headerColumns: any[] = [
    { headerName: 'fuji-climber-management.tab-climber-location-information.reservation-date-and-time', property: 'reserveOn' },
    { headerName: 'fuji-climber-management.tab-climber-location-information.sales-ticket-name', property: 'ticketName' },
    { headerName: 'fuji-climber-management.tab-climber-location-information.ticket-number-before-distribution', property: 'orderId' },
    {
      headerName: 'fuji-climber-management.tab-climber-location-information.ticket-number-after-distribution',
      property: 'orderDistributionId'
    },
    { headerName: 'fuji-climber-management.tab-climber-location-information.the-name-of-the-climber', property: 'recipientName' },
    { headerName: 'fuji-climber-management.tab-climber-location-information.telephone-number', property: 'phoneNumber' },
    { headerName: 'fuji-climber-management.tab-climber-location-information.email-address', property: 'email' },
    {
      headerName: 'fuji-climber-management.tab-climber-location-information.last-acquisition-date-and-time',
      property: 'lastLocationRecordedAt'
    }
  ];

  columns = [
    'reserveOn',
    'ticketName',
    'orderId',
    'orderDistributionId',
    'recipientName',
    'phoneNumber',
    'email',
    'lastLocationRecordedAt'
  ];

  userLocation: UserLocation;
  constructor(
    private menuActionService: MenuActionService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private commonService: CommonService,
    private ticketEditorService: TicketEditorService,
    private cdr: ChangeDetectorRef,
    private fujiClimberManagementService: FujiClimberManagementService,
    private dataService: DataService
  ) {
    // Translate title
    this.placeholderSearch = this.translateService.instant('ticket-manager.sales-record.search');
    this.subscriptions.push(
      // Clear setting
      this.menuActionService.actionClearSetting.subscribe(module => {
        if (
          module == MODULE_NAME[FIELD_COMPONENT.FujiClimberManagementComponent] &&
          this.tabSelected == Constant.CLIMBER_LOCATION_INFORMATION_ENUM
        ) {
          this.clearSearch();
        }
      }),

      //Sort and filter
      this.menuActionService.actionSortFilter.subscribe(module => {
        if (
          module == MODULE_NAME[FIELD_COMPONENT.FujiClimberManagementComponent] &&
          this.tabSelected == Constant.CLIMBER_LOCATION_INFORMATION_ENUM
        ) {
          this.sortAndFilter();
        }
      }),

      // Get language
      this.translateService.onLangChange.subscribe(() => {
        // Translate title
        this.placeholderSearch = this.translateService.instant('ticket-manager.sales-record.search');
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language == 'en' ? 'en' : 'ja';
        this.config = {
          showWeekNumbers: false,
          format: 'YYYY-MM-DD',
          firstDayOfWeek: 'mo',
          unSelectOnClick: false,
          locale: Helper.getLocale(this.languageKey),
          min: '2023-01-01'
        };
        if (this.dataFilterOrder && this.dataFilterOrder.length) {
          this.listOfClimbersDisplay = this.convertListClimber(_.cloneDeep(this.dataFilterOrder));
          this.dataFilterOrderConvert = this.convertListClimber(_.cloneDeep(this.dataFilterOrder));
        }
      }),

      this.dataService.currentData.subscribe(data => {
        this.commonObject = commonService.getCommonObject();
        if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
          if (this.dataFilterOrder && this.dataFilterOrder.length) {
            this.listOfClimbersDisplay = this.convertListClimber(_.cloneDeep(this.dataFilterOrder));
            this.dataFilterOrderConvert = this.convertListClimber(_.cloneDeep(this.dataFilterOrder));
            this.selectClimber(this.listOfClimbersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
          }
        }
      })
    );
    this.commonObject = this.commonService.getCommonObject();
    this.isSortFilter = false;
    this.headerColumns.forEach(e => {
      e.isFilterBy = '';
      e.isSortBy = '';
    });
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language == 'en' ? 'en' : 'ja';
    this.config = {
      showWeekNumbers: false,
      format: 'YYYY-MM-DD',
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey),
      min: '2023-01-01'
    };
    var interval = null;
    interval = setInterval(async () => {
      if (this.informationAccount) {
        clearInterval(interval);
        await this.getAllApplication(this.informationAccount);
      }
    }, 50);
  }

  /**
   * open date picker
   * @param picker
   * @param time
   */
  openDatePicker(picker: DatePickerDirective, time: any): void {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * getTitle
   * @returns
   */
  public getTitleApp(): string {
    return this.listApp?.find(app => app.appId == this.dataSearch['appId'])?.appName;
  }

  /**
   * getTitleSaleTicket
   * @returns
   */
  getTitleSaleTicket(): string {
    if (!this.dataSearch['appId'] || this.dataSearch['appId'] == '-1' || !this.dataSearch['saleTicketId']) {
      return '';
    }
    return this.listTicketSale?.find(ticket => ticket['saleTicketId'] == this.dataSearch['saleTicketId'])?.['ticketName'][this.languageKey];
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(informationAccount?: any): Promise<void> {
    return new Promise<void>(async resolve => {
      this.ticketEditorService.findAllApplication(informationAccount).subscribe(
        async res => {
          this.listApp = Helper.convertResApplication(res);
          if (this.listApp && this.listApp.length) {
            const listAppId = this.listApp.map(e => e.appId).toString();
            this.ticketEditorService.getSalesTickets(this.informationAccount, null, listAppId).subscribe(
              data => {
                this.allTicketSaleList = data;
              },
              error => {
                this.allTicketSaleList = [];
              }
            );
          } else {
            this.listApp = [];
            this.allTicketSaleList = [];
          }
          resolve();
        },
        error => {
          this.listApp = [];
          this.allTicketSaleList = [];
          resolve();
        }
      );
    });
  }

  /**
   * chunkArray
   * @param array
   * @param size
   * @returns
   */
  private chunkArray<T>(array: T[], size: number): T[][] {
    return Array.from({ length: Math.ceil(array.length / size) }, (_, index) => array.slice(index * size, (index + 1) * size));
  }
  /**
   * selectApp
   */
  selectApp(): void {
    if (!this.dataSearch['appId'] || this.dataSearch['appId'] == '-1') {
      this.listTicketSale = [];
      this.dataSearch['saleTicketId'] = undefined;
      return;
    }
    this.listTicketSale = this.allTicketSaleList?.filter(e => e.appId == this.dataSearch['appId']);
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    if (!value) {
      return;
    }
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 21) {
      value = value.substring(0, 21) + '...';
    } else if (value.length > 36) {
      value = value.substring(0, 36) + '...';
    }
    return value;
  }

  /**
   * syncOrderId
   * @param value
   */
  syncOrderId(value: string): void {
    this.dataSearch['orderDistributionId'] = value;
  }

  /**
   * searchConditions
   * @returns
   */
  searchConditions(): boolean {
    if (this.dataSearch['appId'] && this.dataSearch['appId'] !== '-1' && this.dataSearch['saleTicketId'] === '-1') {
      return false;
    }
    if (this.dataSearch['orderId'] || this.dataSearch['recipientName'] || this.dataSearch['phoneNumber'] || this.dataSearch['email']) {
      return true;
    }
    return false;
  }

  /**
   * clearSearch
   */
  clearSearch() {
    this.isSearching = false;
    this.dataSearch = {
      startDate: undefined,
      endDate: undefined,
      appId: '-1',
      saleTicketId: '-1',
      orderId: '',
      orderDistributionId: '',
      recipientName: '',
      phoneNumber: '',
      email: ''
    };
    this.listTicketSale = [];
    if (this.dataFilterOrder && this.dataFilterOrder.length) {
      this.headerColumns.forEach(e => this.clearFilter(e.property));
    }
    this.isClear = false;
    this.listOfClimbersDisplay = [];
    this.dataFilterOrder = [];
    this.dataFilterOrderConvert = [];
    this.climberSelected = null;
    this.userLocation = null;
    this.cdr.detectChanges();
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.listFilterDisplay = undefined;
    this.isCheckAllOptionFilter = undefined;
    this.isSortFilter = false;
    this.columnSortFiltering = '';
    this.listSorted = [];
    this.resetColumnsSort();
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    const index = this.headerColumns.findIndex(e => e.property == property && e.isFilterBy);
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    this.isFilter = false;
    delete this.listCurrentFilter[property];
    if (index != -1) {
      this.filterData();
    }
  }

  /**
   * filterData
   */
  filterData() {
    let listFilterTmp = _.cloneDeep(this.dataFilterOrderConvert);
    if (Object.keys(this.listCurrentFilter).length) {
      for (let filterTmp in this.listCurrentFilter) {
        let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
        listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
      }
    }
    this.listOfClimbersDisplay = listFilterTmp;
    this.isCheckedAllClimbers = this.listOfClimbersDisplay.every(e => e.isMarker);
    this.listOfClimbersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    if (this.listOfClimbersDisplay?.length) {
      this.selectClimber(this.listOfClimbersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
      let div = document.getElementById('dataTotalSale');
      div.scrollTop = 0;
    } else {
      this.climberSelected = null;
      this.userLocation = null;
      this.cdr.detectChanges();
    }
  }

  /**
   * sort multiple
   * @param dataSort list properties and sort type sorted
   */
  public dynamicSortMultiple(dataSort: any): any {
    return function(object1, object2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  /**
   * reset column sort disable in list
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
  }

  /**
   *sortAndFilter
   */
  sortAndFilter() {
    this.isSortFilter = !this.isSortFilter;
    if (!this.listOfClimbersDisplay || !this.listOfClimbersDisplay.length) {
      return;
    }
    if (!this.isSortFilter) {
      this.resetSortFilter();
      if (this.dataFilterOrderConvert && this.dataFilterOrderConvert.length) {
        this.headerColumns.forEach(e => this.clearFilter(e.property));
        this.selectClimber(this.listOfClimbersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        let div = document.getElementById('dataTotalSale');
        div.scrollTop = 0;
      }
    }
  }

  /**
   * resetSortFilter
   */
  resetSortFilter() {
    this.listOfClimbersDisplay = _.cloneDeep(this.dataFilterOrderConvert);
    this.isCheckedAllClimbers = this.listOfClimbersDisplay.every(e => e.isMarker);
    this.resetColumnsSort();
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
    this.cdr.detectChanges();
  }

  /**
   *showPopupSortFilter
   * @param title
   * @param event
   */
  showPopupSortFilter(property: string, event) {
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = property;
      this.fetchFilterData(property);
    }
  }

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    let isFiltered = false;
    let objectTemp = {};
    let listFilterTmp = _.cloneDeep(this.dataFilterOrderConvert);
    let listCurrentFilterClone = _.cloneDeep(this.listCurrentFilter);
    for (let filterTmp in listCurrentFilterClone) {
      if (filterTmp == property) {
        isFiltered = true;
        objectTemp[property] = listCurrentFilterClone[filterTmp].filter(e => e.isChecked);
        continue;
      }
      let filter = listCurrentFilterClone[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    let mapProperty = [...new Set(listFilterTmp?.map(e => e[property]))];
    let mapChecked = objectTemp[property]?.map(e => e.name);
    if (isFiltered) {
      mapProperty.forEach(e => {
        if (!mapChecked.includes(e)) {
          objectTemp[property].push({
            isChecked: false,
            name: e
          });
        }
      });
      let templ = _.cloneDeep(objectTemp[property]);
      let cnt = 0;
      for (let index = 0; index < templ.length; index++) {
        if (!mapProperty.includes(templ[index].name)) {
          objectTemp[property].splice(index - cnt, 1);
          cnt++;
        }
      }
      delete listCurrentFilterClone[property];
      listCurrentFilterClone[property] = objectTemp[property];
    } else {
      objectTemp[property] = new Array<any>();
      mapProperty.forEach(e => {
        objectTemp[property].push({
          isChecked: true,
          name: e
        });
      });
      listCurrentFilterClone[property] = objectTemp[property];
    }
    this.listFilterDisplay = _.cloneDeep(listCurrentFilterClone[property]);
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    this.listFilterDisplayOriginal = _.cloneDeep(this.listFilterDisplay);
    // get list memorize checked
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplay?.every(filter => filter.isChecked);
  }

  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.listOfClimbersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.selectClimber(this.listOfClimbersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    let div = document.getElementById('dataTotalSale');
    div.scrollTop = 0;
    this.isShowPopUpSortFilter = false;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
  }

  /**
   * showCustomSort
   */
  showCustomSort() {
    this.isShowPopUpSortFilter = false;

    // replace template with display 1, display 2
    let propertySorts = _.cloneDeep(this.headerColumns);
    propertySorts.forEach(e => {
      e.headerName = this.translateService.instant(e.headerName);
    });
    // show dialog custom sort
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }

        // sort
        this.listOfClimbersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        this.selectClimber(this.listOfClimbersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        let div = document.getElementById('dataTotalSale');
        div.scrollTop = 0;
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
      }
    });
  }

  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.listFilterDisplay.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
  }

  /**
   * change checked
   * @param index index of option filter
   */
  public checkOptionFilter(index: number): void {
    this.listFilterDisplay[index].isChecked = !this.listFilterDisplay[index].isChecked;
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * filterOder
   * @param property
   * @param isFilterFirstTime
   * @returns
   */
  filterOder(property: string, isFilterFirstTime: boolean) {
    // do not filter all
    if (this.listFilterDisplay.every(e => !e.isChecked)) {
      this.listFilterDisplay.forEach(option => {
        option.isChecked = true;
      });
    }
    this.listCurrentFilter[property] = _.cloneDeep(this.listFilterDisplay);
    if (this.listFilterDisplay.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    const isEqual = JSON.stringify(this.listFilterDisplayOriginal) === JSON.stringify(this.listFilterDisplay);
    if (isEqual) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    this.isFilter = true;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.filterData();
    // this.ordersDisplay = listFilterTmp;
    this.listOfClimbersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;
    this.controlCheckBoxCheckAllFilter();
    if (!isFilterFirstTime) {
      if (this.listOfClimbersDisplay?.length) {
        this.selectClimber(this.listOfClimbersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        this.cdr.detectChanges();
        let div = document.getElementById('dataTotalSale');
        div.scrollTop = 0;
      } else {
        this.climberSelected = null;
        this.userLocation = null;
      }
    }
  }

  /**
   * search
   * @returns
   */
  async search(): Promise<void> {
    if (!this.validateSearch()) {
      return;
    }

    this.isSearching = true;
    let combinedResults: any[] = [];
    let nextToken: string | undefined;

    try {
      do {
        let processedNextToken: string | undefined;
        if (nextToken) {
          try {
            // Parse nextToken JSON string
            const tokenObj = JSON.parse(nextToken);
            // Nếu có phoneNumber, encode riêng phần số điện thoại
            if (tokenObj.attribute === 'phoneNumber' && tokenObj.attributeValue) {
              // Tách prefix 'phoneNumber#' và encode phần số điện thoại
              const phoneValue = tokenObj.attributeValue.replace('phoneNumber#', '');
              tokenObj.attributeValue = 'phoneNumber#' + phoneValue.replace(/\+/g, '%2B');
              processedNextToken = JSON.stringify(tokenObj);
            } else {
              processedNextToken = nextToken;
            }
          } catch (e) {
            console.error('Error processing nextToken:', e);
            processedNextToken = nextToken;
          }
        }

        // Step 1: Call API 4-1 (first call without nextToken, subsequent calls with nextToken)
        const api41Response = await this.fujiClimberManagementService.getUsersLocations(this.dataSearch, processedNextToken).toPromise();

        if (!api41Response?.userLocationList?.length) {
          break;
        }
        let listOrderDistributionId = Array.from(new Set(api41Response?.userLocationList?.map(e => e.orderDistributionId) || []));
        let orderDistributionIdString = listOrderDistributionId?.length ? listOrderDistributionId.join(',') : '';

        // Step 2: Call API 17-1 with reserveOn and orderDistributionId
        const api171Response = await this.fujiClimberManagementService
          .getDistributionTickets({
            startDate: this.dataSearch['startDate'],
            endDate: this.dataSearch['endDate'],
            orderDistributionId: orderDistributionIdString
          })
          .toPromise();

        // Step 3: Filter matching users
        const matchingUsers = api171Response
          .filter(user171 => api41Response.userLocationList.some(user41 => user41.orderDistributionId === user171.orderDistributionId))
          .map(user171 => {
            const user41 = api41Response.userLocationList.find(u => u.orderDistributionId === user171.orderDistributionId);

            return {
              userId: user171.userId,
              reserveOn: user171.reserveOn,
              receivedAt: user171.receivedAt,
              ticketId: user41.saleTicketId,
              ticketName: this.getTicketName(user41.saleTicketId),
              orderDistributionId: user41?.orderDistributionId || '',
              orderId: user41?.orderId || '',
              recipientName: user41?.recipientName || '',
              phoneNumber: user41?.phoneNumber || '',
              email: user41?.email || '',
              lastLocationRecordedAt: user41?.lastLocationRecorded.locationRecordedAt || '',
              longitude: user41?.lastLocationRecorded.longitude || 0,
              latitude: user41?.lastLocationRecorded.latitude || 0,
              isMarker: true
            };
          });

        combinedResults.push(...matchingUsers);
        nextToken = api41Response.nextToken;
      } while (nextToken !== 'undefined' && nextToken !== undefined);

      // Sort results by reserveOn date (old to new)
      // If reserveOn dates are equal, sort by receivedAt (old to new)
      const sortedResults = combinedResults.sort((a, b) => {
        const reserveOnCompare = new Date(a.reserveOn).getTime() - new Date(b.reserveOn).getTime();
        if (reserveOnCompare === 0) {
          return new Date(a.receivedAt).getTime() - new Date(b.receivedAt).getTime();
        }
        return reserveOnCompare;
      });

      // Handle results exceeding 100 records
      if (sortedResults.length > 100) {
        this.dialogService.showDialog(
          DialogMessageComponent,
          {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant(
                'fuji-climber-management.tab-climber-location-information.msg.warning-greater-than-100-records'
              )
            }
          },
          () => {
            this.processingDataAfterSearching(sortedResults);
          }
        );
      } else {
        this.processingDataAfterSearching(sortedResults);
      }
    } catch (error) {
      console.error('Error during search:', error);
      this.isSearching = false;
    }
  }

  /**
   * processingDataAfterSearching
   * @param data
   * @returns
   */
  processingDataAfterSearching(data: any[]): void {
    this.dataFilterOrder = data;
    this.listOfClimbersDisplay = this.convertListClimber(_.cloneDeep(this.dataFilterOrder));
    this.dataFilterOrderConvert = this.convertListClimber(_.cloneDeep(this.dataFilterOrder));
    this.userLocation = null;
    this.cdr.detectChanges();
    if (!this.dataFilterOrder || this.dataFilterOrder.length == 0) {
      this.climberSelected = null;
      this.userLocation = null;
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('fuji-climber-management.tab-climber-location-information.msg.no-data-found-after-searching')
        }
      });
      return;
    }
    this.isCheckedAllClimbers = true;
    this.selectClimber(this.listOfClimbersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    let div = document.getElementById('dataTotalSale');
    div.scrollTop = 0;
  }

  /**
   * convertListClimber
   * @param listFilterTmp
   * @returns
   */
  convertListClimber(listFilterTmp): any {
    listFilterTmp.forEach(e => {
      for (let property in e) {
        if (this.checkObjectType(e[property])) {
          e[property] = this.languageKey == 'en' ? e[property]?.en : e[property]?.ja;
        }
      }
      e['reserveOn'] = this.displayTime(e.reserveOn);
      e['lastLocationRecordedAt'] = Helper.convertMultipleTimeFormatsToCMPTime(
        e.lastLocationRecordedAt,
        this.commonObject,
        'YYYY-MM-DD HH:mm:ss'
      );
    });
    return listFilterTmp;
  }

  /**
   * checkObjectType
   * @param input
   * @returns
   */
  checkObjectType(input) {
    return typeof input == 'object';
  }

  /**
   * selectClimber
   * @param climber
   * @param e
   * @returns
   */
  selectClimber(climber: any, e: any): void {
    if (e?.target?.id === 'checkBoxClimber' || !climber) {
      return;
    }
    this.climberSelected = climber;
    this.fujiClimberManagementService.getUsersLocation(this.climberSelected.userId).subscribe(res => {
      this.userLocation = {
        ...res,
        orderDistributionId: this.climberSelected.orderDistributionId,
        isMarker: this.climberSelected.isMarker
      };
      if (this.userLocation?.locationRecords?.length) {
        this.userLocation.locationRecords = this.userLocation.locationRecords.map(record => ({
          ...record,
          locationRecordedAt: Helper.convertMultipleTimeFormatsToCMPTime(
            record.locationRecordedAt,
            this.commonObject,
            'YYYY-MM-DD HH:mm:ss'
          )
        }));
      }
      this.cdr.detectChanges();
    });
  }

  /**
   * checkAll
   */
  checkAll() {
    this.isCheckedAllClimbers = !this.isCheckedAllClimbers;
    const listOrderDistributionIds = this.listOfClimbersDisplay.map(e => e.orderDistributionId);
    const updateCheckAllItems = (list: any[]) => {
      list.forEach(element => {
        if (listOrderDistributionIds.includes(element.orderDistributionId)) {
          element.isMarker = this.isCheckedAllClimbers;
        }
      });
    };

    [this.listOfClimbersDisplay, this.dataFilterOrder, this.dataFilterOrderConvert].forEach(updateCheckAllItems);
    if (this.userLocation && listOrderDistributionIds.includes(this.userLocation.orderDistributionId)) {
      this.userLocation = {
        ...this.userLocation,
        isMarker: this.isCheckedAllClimbers
      };
    }
    this.cdr.detectChanges();
  }

  /**
   * changeCheckedClimber
   * @param id
   * @param e
   */
  changeCheckedClimber(id: string, e): void {
    e.stopPropagation();
    const updateItem = list => {
      const index = list.findIndex(item => item.orderDistributionId == id);
      if (index !== -1) {
        list[index].isMarker = !list[index].isMarker;
      }
      if (this.userLocation && this.userLocation?.orderDistributionId == id) {
        this.userLocation = {
          ...this.userLocation,
          isMarker: list[index].isMarker
        };
      }
    };
    this.listOfClimbersDisplay = [...this.listOfClimbersDisplay];
    updateItem(this.listOfClimbersDisplay);
    updateItem(this.dataFilterOrder);
    updateItem(this.dataFilterOrderConvert);
    this.isCheckedAllClimbers = this.listOfClimbersDisplay.every(e => e.isMarker);
    this.cdr.detectChanges();
  }

  /**
   * validateSearch
   * @returns
   */
  validateSearch(): boolean {
    const currentDate = new Date();
    var year = currentDate.getFullYear();
    var month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
    var day = ('0' + currentDate.getDate()).slice(-2);
    var formattedDate = year + '-' + month + '-' + day;
    if (this.dataSearch['startDate'] > formattedDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('fuji-climber-management.tab-climber-location-information.msg.error-selecting-future-date')
        }
      });
      return false;
    } else if (this.dataSearch['endDate'] > formattedDate) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('fuji-climber-management.tab-climber-location-information.msg.error-selecting-future-date')
        }
      });
      return false;
    } else if (moment(this.dataSearch['endDate']).diff(moment(this.dataSearch['startDate']), 'days') < 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant(
            'fuji-climber-management.tab-climber-location-information.msg.error-when-selecting-start-date-greater-than-end-date'
          )
        }
      });
      return false;
    } else if (moment(this.dataSearch['endDate']).diff(moment(this.dataSearch['startDate']), 'days') >= 2) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant(
            'fuji-climber-management.tab-climber-location-information.msg.error-when-selecting-outside-the-appointment-date-range'
          )
        }
      });
      return false;
    }
    return true;
  }

  /**
   * displayScheduleAt
   * @param time
   * @returns
   */
  displayTime(time: string) {
    if (!time) {
      return '';
    }
    return time.substring(0, 19).replace('T', ' ');
  }

  /**
   * handleMapClimberSelected
   * @param orderDistributionId
   * @returns
   */
  handleMapClimberSelected(orderDistributionId: string): void {
    const index = this.listOfClimbersDisplay.findIndex(c => c.orderDistributionId === orderDistributionId);
    if (index == -1) return;
    this.selectClimber(this.listOfClimbersDisplay[index], null);
    this.cdr.detectChanges();
  }

  /**
   * getTicketName
   * @param saleTicketId
   * @returns
   */
  getTicketName(saleTicketId: number): string {
    let index = this.allTicketSaleList.findIndex(e => e?.saleTicketId == saleTicketId);
    let ticketName = index == -1 ? '' : this.allTicketSaleList[index]?.ticketName;
    return ticketName;
  }

  /**
   * handleSpace
   * @param value
   */
  handleSpace(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const updatedValue = inputValue.replace(/\u3000/g, '\u0020');
    this.dataSearch['recipientName'] = updatedValue;
    inputElement.value = updatedValue;
  }
}
